<template>
  <v-container>
    <v-form ref="accountDetails">
      <v-row class="mt-3">
        <v-col cols="12" lg="6" md="6">
          <label
            >{{ $t("paymentType") }}
            <span style="color: #f00">*</span>
          </label>
          <v-select
            v-model="accountDetails.payment_type"
            :placeholder="$t('select_payment_type')"
            :menu-props="{ bottom: true, offsetY: true }"
            :items="paymentTypes"
            required
            :disabled="!hidepaymentMode"
            outlined
            dense
            :rules="paymenttypeValidateions"
            class=""
          >
          </v-select
        ></v-col>

        <v-col lg="6" md="6" cols="12">
          <label
            >{{ $t("reason") }}
            <span style="color: #f00">*</span>
          </label>

          <v-select
            v-model="reason"
            :placeholder="$t('purpose_of_remittance')"
            :items="purposeOfRemittenceList"
            :menu-props="{ bottom: true, offsetY: true }"
            required
            outlined
            dense
            class=""
            :rules="reasonValidations"
          >
          </v-select>
        </v-col>

        <v-col lg="6" md="6" cols="12">
          <label
            >{{ $t("sourceoffunds") }}
            <span style="color: #f00">*</span>
          </label>

          <v-select
            v-model="sourceoffunds"
            :placeholder="$t('source_of_funds_send_money')"
            :items="sourceOfFundsList"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="text"
            item-value="value"
            required
            outlined
            dense
            :rules="sourceoffundsValidation"
            class=""
          >
          </v-select>
        </v-col>

        <v-col lg="6" md="6" cols="12">
          <label
            >{{ $t("remarks") }}
            <span v-if="!hidepaymentMode" style="color: #f00">*</span>
          </label>
          <v-text-field
            v-model="accountDetails.remarks"
            :placeholder="$t('remarks')"
            required
            outlined
            dense
            :rules="hidepaymentMode ? '' : remarksValidations"
            class=""
          >
          </v-text-field
        ></v-col>

        <v-col
          lg="6"
          md="6"
          cols="12"
          class="ms-0"
          v-if="accountDetails.payment_type === 'Bank Transfer/Cash Deposit'"
        >
          <label
            >{{ $t("bankadvice") }}
            <span style="color: #f00">*</span>
          </label>
          <div class="d-flex">
            <v-file-input
              v-model="imageData.bankAdvice"
              accept="image/png, image/jpeg, image/bmp,.pdf"
              :placeholder="$t('upload_bank_advice')"
              ref="Bankadvice"
              dense
              :rules="
                imageData.bankAdvice.length === 0 ? ['Bank Advice is required'] : []
              "
              outlined
              prepend-icon=""
              @change="genrateURL('bankAdviceUrl', imageData.bankAdvice, 'bank_advice')"
            >
              <template v-slot:append>
                <v-icon aria-hidden="false" class="color-primary" @click="open">
                  mdi-cloud-upload
                </v-icon>
              </template>
            </v-file-input>
            <v-img
              v-if="imageData.bankAdviceUrl"
              class="ml-1"
              max-height="40"
              max-width="50"
              :src="imageData.bankAdviceUrl"
              @click="handleImageViewer(imageData.bankAdviceUrl)"
            />
          </div>
        </v-col>
        <v-col
          lg="6"
          md="6"
          cols="12"
          class="ms-0"
          v-if="accountDetails.payment_type === 'Bank Transfer/Cash Deposit'"
        >
          <label
            >{{ $t("invoiceadvice") }}
            <span style="color: #f00">*</span>
          </label>
          <div class="d-flex">
            <v-file-input
              v-model="imageData.custInvoice"
              accept="image/png, image/jpeg, image/bmp,.pdf"
              :placeholder="$t('invoice_advice')"
              ref="custInvoice"
              dense
              :rules="
                imageData.bankAdvice.length === 0 ? ['Customer Invoice is required'] : []
              "
              outlined
              prepend-icon=""
              @change="
                genrateURL('custInvoiceUrl', imageData.custInvoice, 'cust_invoice')
              "
            >
              <template v-slot:append>
                <v-icon aria-hidden="false" class="color-primary" @click="uploadCust()">
                  mdi-cloud-upload
                </v-icon>
              </template>
            </v-file-input>
            <v-img
              v-if="imageData.custInvoiceUrl"
              class="ml-1"
              max-height="40"
              max-width="50"
              :src="imageData.custInvoiceUrl"
              @click="handleImageViewer(imageData.custInvoiceUrl)"
            />
          </div>
        </v-col>
        <v-col lg="6" md="6" cols="12" v-if="!self" class="m-0">
          <label>
            {{ $t("businessInvoice") }}
          </label>

          <div class="d-flex">
            <v-file-input
              v-model="imageData.businessInvoice"
              accept="image/png, image/jpeg, image/bmp"
              :placeholder="$t('upload_bank_advice')"
              dense
              :rules="
                imageData.businessInvoice && imageData.businessInvoice.length === 0
                  ? bankadviceValidations
                  : []
              "
              outlined
              prepend-icon=""
              @change="
                genrateURL(
                  'businessInvoiceUrl',
                  imageData.businessInvoice,
                  'business_invoice'
                )
              "
            >
            </v-file-input>
            <v-img
              v-if="imageData.businessInvoiceUrl"
              class="ml-1"
              max-height="40"
              max-width="50"
              :src="imageData.businessInvoiceUrl"
              @click="handleImageViewer(imageData.businessInvoiceUrl)"
            />
          </div>
        </v-col>

        <v-col cols="12" md="12">
          <v-btn
            class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
            @click="onClickBack"
            outlined
            ><v-icon dark left>mdi-arrow-left</v-icon> {{ $t("backLabel") }}
          </v-btn>
          <v-btn
            class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
            @click="onclickNext"
            outlined
          >
            {{ $t("next") }}
            <v-icon dark right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <image-viewer
      :showDialog="isImageViewer"
      :picture="{
        PicHeight: '400',
        PicWidth: 500,
        Pic: imageViewerUrl,
      }"
      @closeImageViewer="handleCloseImageViewer"
    ></image-viewer>
  </v-container>
</template>

<script>
// import Loading from "vue-loading-overlay";
import commonService from "@/services/CommonService/commonService";
import imageViewer from "../../tools/imageViewer.vue";
import { validations } from "@/utils/validation.js";
import { mapState } from "vuex";
const {
  reasonValidations,
  paymenttypeValidateions,
  remarksValidations,
  sourceoffundsValidation,
  bankadviceValidations,
} = validations;
export default {
  name: "AccountSection",
  components: {
    // Loading,
    imageViewer,
  },
  data() {
    return {
      reasonValidations,
      paymenttypeValidateions,
      remarksValidations,
      sourceoffundsValidation,
      bankadviceValidations,
      sourceOfFundsList: [],
      is_partner: false,
      user_type: "",
      imageData: {
        bankAdvice: [],
        bankAdviceUrl: "",
        custInvoice: [],
        custInvoiceUrl: "",
        businessInvoice: [],
        businessInvoiceUrl: "",
      },
      isImageViewer: false,
      imageViewerUrl: "",
      name: "",
      isSubmitted: false,
      fullPage: true,
      height: 100,
      width: 100,
      paymentTypes: [],
      reason: "",
      sourceoffunds: "",
      accountDetails: {
        payment_type: "",
        reason: "",
        reason_text: "",
        sourceoffunds: "",
        sourceoffund_text: "",
        remarks: "",
        bank_advice: [],
        cust_invoice: [],
        business_Invoice: [],
      },
      purposeOfRemittenceList: [],
      purposeOfRemittenceobj: [],
      sourceOfFundsobj: [],
      hidepaymentMode: true,
    };
  },
  props: {
    self: {
      type: Boolean,
      default: true,
    },
    paymentDetails: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userDetails"]),
  },
  mounted() {
    this.getSourceOfFunds();
    this.getPurposeOfRemittence();
    this.is_partner = this.$getLocalStorage("is_partner");
    this.user_type = sessionStorage.getItem("user_type");
    if (this.is_partner) {
      const payment_modes = sessionStorage.getItem("supported_payment_modes");
      console.log("test", payment_modes);
      if (payment_modes.includes("\\054")) {
        this.paymentTypes = payment_modes
          .replace(/"/g, "")
          .replace(/\\054/g, ",")
          .split(",");
      } else {
        this.paymentTypes = payment_modes.split(",");
      }
    } else {
      this.paymentTypes = this.$getLocalStorage("supported_payment_modes").split(",");
    }
    if (this.paymentTypes.length == 1 && this.is_partner) {
      this.accountDetails.payment_type = this.paymentTypes[0];
      this.hidepaymentMode = false;
    }
    console.log("twist2", this.paymentTypes);
    // // validating partner user for mapping payment type
    // if (this.is_partner) {
    //   this.paymentTypes = ["WALLET"];
    //   this.accountDetails.payment_type = "WALLET";
    //   this.hidepaymentMode = false;
    // } // validating partner user for mapping payment type
    // else if (!["null", "", null, undefined].includes(this.user_type)) {
    //   this.paymentTypes = ["FPX (Internet Banking)","E-Wallets", "Bank Transfer/Cash Deposit"];
    //   this.accountDetails.payment_type = "FPX (Internet Banking)";
    //   // if (this.user_type === "BUS") {
    //   //   this.paymentTypes = ["Bank Transfer/Cash Deposit"];
    //   //   this.accountDetails.payment_type = "Bank Transfer";
    //   // } else {
    //   //   this.paymentTypes = ["FPX"];
    //   //   this.accountDetails.payment_type = "FPX";
    //   // }
    // }
    console.log("userDetails", this.userDetails);
    console.log("this.accountDetails.payment_type", this.accountDetails.payment_type);
  },
  watch: {
    paymentDetails(val) {
      if (val) {
        this.accountDetails = { ...val };
      }
    },
    reason(val) {
      console.log("account details", val);
      this.purposeOfRemittenceobj.map((i) => {
        if (i.value === val) {
          this.accountDetails.reason = i.value_code;
          this.accountDetails.reason_text = i.value;
        }
      });
    },
    sourceoffunds(val) {
      console.log("sourceoffunds", val);
      this.sourceOfFundsobj.map((i) => {
        if (i.value === val) {
          this.accountDetails.sourceoffunds = i.value_code;
          this.accountDetails.sourceoffund_text = i.value;
        }
      });
    },
  },
  methods: {
    open() {
      this.$refs.Bankadvice.$el.querySelector("input").click();
    },
    uploadCust() {
      this.$refs.custInvoice.$el.querySelector("input").click();
    },

    async getPurposeOfRemittence() {
      const responseData = await commonService.getDropdownList("POR");
      console.log("purpose of remittance:", responseData);
      try {
        if (responseData.data.status_code == 200) {
          this.purposeOfRemittenceList =
            responseData.data.data && responseData.data.data.map((e) => e.value);
          this.purposeOfRemittenceobj =
            responseData.data.data && responseData.data.data.map((e) => e);
        }
      } catch (err) {
        console.log(err);
      }
      // let responseData = await this.$store.dispatch(
      //   "Transactional/get_remittence_reasonSendpay"
      // );
      // console.log("purpose of remittance: ", responseData);
      // this.purposeOfRemittenceList =
      //   responseData.data && responseData.data.map((e) => e.value);
      // this.purposeOfRemittenceobj =
      //   responseData.data && responseData.data.map((e) => e);
    },
    async getSourceOfFunds() {
      const responseData = await commonService.getDropdownList("SOF");
      console.log("SOF", responseData);
      try {
        if (responseData.data.status_code == 200) {
          this.sourceOfFundsList =
            responseData.data.data && responseData.data.data.map((e) => e.value);
          this.sourceOfFundsobj =
            responseData.data.data && responseData.data.data.map((e) => e);
        }
      } catch (err) {
        console.log(err);
      }
      // let responseData = await this.$store.dispatch("Transactional/getSOF");
      // this.sourceOfFundsList = responseData.data && responseData.data.map((e) => e.value);
      // this.sourceOfFundsobj = responseData.data && responseData.data.map((e) => e);
    },
    handleImageViewer(url) {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = url;
    },
    genrateURL(keyField, value, assignKey) {
      if (!keyField) return;
      if (!value) {
        this.imageData[`${keyField}`] = null;
        this.accountDetails[`${assignKey}`] = null;
      } else {
        this.imageData[`${keyField}`] = URL.createObjectURL(value);
        this.accountDetails[`${assignKey}`] = value;
      }
    },

    handleCloseImageViewer() {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = "";
    },
    onclickNext() {
      const validate = this.$refs.accountDetails.validate();
      if (validate) this.$emit("onClickNext", { paymentDetails: this.accountDetails });
      console.log("this.accountDetails", this.accountDetails);
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-password {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-confirm {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-swift {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
