<template>
  <v-container>
    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.7"
      color="#ff6b00"
    />
    <v-row>
      <v-col
        cols="12"
        v-if="isTrans == 'true'"
        class="d-flex w-100 justify-content-end"
      >
        <v-btn color="primary" @click="onClickCancel">
          {{ $t("cancel_beneficary") }}</v-btn
        >
      </v-col>

      <v-col cols="12" md="6">
        <label
          >{{ $t("businessname_companyname_benificary")
          }}<span style="color: #f00">*</span></label
        >
        <v-text-field
          v-model="beneficiaryReqBody.full_name"
          dense
          :placeholder="$t('companyname_benificary')"
          required
          :error-messages="companyNameErrors"
          @input="$v.beneficiaryReqBody.full_name.$touch()"
          @blur="$v.beneficiaryReqBody.full_name.$touch()"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label>{{ $t("company_registration_number_beneficary") }}</label>
        <v-text-field
          v-model="beneficiaryReqBody.id_no"
          dense
          :placeholder="$t('company_registration_number_beneficary')"
          required
          :error-messages="companyRegNumberErrors"
          @input="$v.beneficiaryReqBody.id_no.$touch()"
          @blur="$v.beneficiaryReqBody.id_no.$touch()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("nature_of_business_beneficary") }}</label>
        <v-autocomplete
          v-model="beneficiaryReqBody.nature_of_business"
          :items="[
            'Government sector',
            'Military sector',
            'Private sector',
            'Technology sector',
            'Service sector',
          ]"
          :placeholder="$t('nature_of_business_beneficary')"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("address") }}<span style="color: #f00">*</span></label>
        <v-text-field
          v-model="beneficiaryReqBody.address"
          :placeholder="$t('enter_address_beneficary')"
          :error-messages="addressErrors"
          @input="$v.beneficiaryReqBody.address.$touch()"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <label class="">
          {{ $t("relationship") }}<span style="color: #f00">*</span>
        </label>
        <v-autocomplete
          v-model="SelectedRelationship"
          :items="relation_options"
          item-text="txt"
          item-value="val"
          :error-messages="RelationErrors"
          @input="$v.SelectedRelationship.$touch()"
          :placeholder="$t('relationship_placeholder')"
          outlined
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label class="mt-1"
          >{{ $t("country") }}<span style="color: #f00">*</span></label
        >
        <v-autocomplete
          v-model="countrySelected"
          :placeholder="$t('select_country_beneficary')"
          :items="country_options"
          item-text="txt"
          item-value="val"
          :error-messages="countryErrors"
          @input="
            $v.countrySelected.$touch();
            onChangeCountry($event);
          "
          outlined
          required
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("Province") }}</label>
        <v-autocomplete
          v-model="stateSelected"
          :items="state_options"
          item-text="txt"
          item-value="val"
          @change="onChangeState($event)"
          :placeholder="$t('province_placeholder')"
          outlined
          required
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label class="mt-1">{{ $t("city") }}</label>
        <v-autocomplete
          v-model="citySelected"
          :items="city_options"
          item-text="txt"
          item-value="val"
          :placeholder="$t('city_placeholder_beneficary')"
          outlined
          required
          dense
        >
        </v-autocomplete>
        <!-- <v-text-field
          v-else
          v-model="cityValue"
          :placeholder="$t('enter_city')"
          :error-messages="citytextErrors"
          @input="$v.cityValue.$touch()"
          @blur="$v.cityValue.$touch()"
          outlined
          required
          dense
        >
        </v-text-field> -->
      </v-col>

      <v-col cols="12" md="6">
        <label>{{ $t("postalCode") }}</label>
        <v-text-field
          v-model="beneficiaryReqBody.postal_code"
          :error-messages="postalCodeErrors"
          @input="$v.beneficiaryReqBody.postal_code.$touch()"
          :placeholder="$t('postalcode_placeholder_beneficary')"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <!-- <v-btn
          class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          @click="onClickBack"
          ><v-icon dark left>mdi-arrow-left</v-icon>
          {{ $t("backLabel") }}</v-btn
        > -->
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          @click="onClickNext"
          >{{ $t("next") }} <v-icon dark right>mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import {
  required,
  alphaNum,
  numeric,
  // requiredIf,
} from "vuelidate/lib/validators";
import { alpha } from "../../utils/validate";
import { addresslen } from "../../utils/validate";
import Loading from "vue-loading-overlay";
import commonService from "@/services/CommonService/commonService";
import alerts from "@/mixins/alerts";

export default {
  name: "BasicDetails",
  mixins: [alerts],
  data: () => ({
    loader: false,
    doistate: false,
    isValidNumber: false,
    country_options: [],
    state_options: [],
    city_options: [],
    isSubmitted: false,
    countrySelected: {},
    SelectedRelationship: {},
    relation_options: [],
    stateSelected: {},
    citySelected: {},
    country_code: "",
    countryobjs: [],
    cityValue: "",
    haveCities: true,
  }),
  props: {
    beneficiaryReqBody: {
      type: Object,
      required: true,
    },
    dialogisOpen: {
      type: Boolean,
    },
    // BusrecieverData: {
    //   type: Object,
    //   required: true,
    // },
    isTrans: {
      type: String,
      default: "",
    },
  },
  components: {
    // VuePhoneNumberInput,
    Loading,
  },
  watch: {
    dialogisOpen: {
      handler(val) {
        if (val) {
          this.getCountryList();
        }
      },
    },
    // BusrecieverData: {
    //   handler(data) {
    //     console.log("data", data);
    //     this.beneficiaryReqBody.full_name = data.full_name;
    //     this.beneficiaryReqBody.id_no = data.id_no;
    //     this.beneficiaryReqBody.address = data.address;
    //     this.beneficiaryReqBody.nature_of_business = data.nature_of_business;
    //     this.beneficiaryReqBody.postal_code = data.postal_code;
    //   },
    // },
    SelectedRelationship(newVal) {
      this.beneficiaryReqBody.relationship_code = newVal.code;
      this.beneficiaryReqBody.relationship_name = newVal.name;
    },
    countrySelected(newVal) {
      console.log("selcted: ", newVal);
      this.beneficiaryReqBody.country_code = newVal.iso3;
      this.beneficiaryReqBody.Remittee_country = newVal.name;
      this.countryobjs.map((i) => {
        if (i.iso_alpha3 === newVal.iso3) {
          this.$emit("onCountrySelect", i.iso_alpha3);
          this.beneficiaryReqBody.currency_code = i.currency_code;
          // to map phone number code
          this.beneficiaryReqBody.phone_number_cty_code = i.iso_alpha2;
        }
      });
    },
    stateSelected(newVal) {
      this.beneficiaryReqBody.state_code = newVal.state_code;
      this.beneficiaryReqBody.Remittee_state = newVal.state_name;
    },
    citySelected(newVal) {
      this.beneficiaryReqBody.Remittee_city = newVal.name;
      this.beneficiaryReqBody.city_code = newVal.id;
    },
    cityValue(newVal) {
      this.beneficiaryReqBody.Remittee_city = newVal;
    },
  },
  computed: {
    ...mapGetters("Authentication", [
      "country_list",
      "state_list",
      "city_list",
    ]),
    countryErrors() {
      const errors = [];
      if (!this.$v.countrySelected.$dirty) return errors;
      !this.$v.countrySelected.required &&
        errors.push(this.$t("select_country"));
      return errors;
    },
    // provinceErrors() {
    //   const errors = [];
    //   if (!this.$v.stateSelected.$dirty) return errors;
    //   !this.$v.stateSelected.required &&
    //     errors.push(this.$t("select_province"));
    //   return errors;
    // },
    // cityErrors() {
    //   const errors = [];
    //   if (!this.$v.citySelected.$dirty) return errors;
    //   !this.$v.citySelected.required && errors.push(this.$t("select_city"));
    //   return errors;
    // },
    // citytextErrors() {
    //   const errors = [];
    //   if (!this.$v.cityValue.$dirty) return errors;
    //   !this.$v.cityValue.required && errors.push(this.$t("enter_city"));
    //   !this.$v.cityValue.alpha && errors.push(this.$t("invalid_city_name"));
    //   return errors;
    // },
    RelationErrors() {
      const errors = [];
      if (!this.$v.SelectedRelationship.$dirty) return errors;
      !this.$v.SelectedRelationship.required &&
        errors.push(this.$t("select_Relation"));
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.postal_code.$dirty) return errors;
      // !this.$v.beneficiaryReqBody.postal_code.required &&
      //   errors.push(this.$t("postal_code"));
      !this.$v.beneficiaryReqBody.postal_code.numeric &&
        errors.push(this.$t("invalid_postal_code"));
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.address.$dirty) return errors;
      !this.$v.beneficiaryReqBody.address.required &&
        errors.push(this.$t("address"));
      !this.$v.beneficiaryReqBody.address.addresslen &&
        errors.push(this.$t("invalid_address"));
      return errors;
    },
    companyNameErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.full_name.$dirty) return errors;
      !this.$v.beneficiaryReqBody.full_name.required &&
        errors.push(this.$t("company_name"));
      !this.$v.beneficiaryReqBody.full_name.alpha &&
        errors.push(this.$t("invalid_company_name"));
      return errors;
    },
    companyRegNumberErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.id_no.$dirty) return errors;

      !this.$v.beneficiaryReqBody.id_no.alphaNum &&
        errors.push(this.$t("invalid_register_num"));
      return errors;
    },
    incorporateDateErrors() {
      const errors = [];
      if (!this.$v.beneficiaryReqBody.date_of_incorporation.$dirty)
        return errors;
      !this.$v.beneficiaryReqBody.date_of_incorporation.required &&
        errors.push(this.$t("incorporation_date"));
      if (this.doistate) {
        errors.push(this.$t("invalid_incorp_date"));
      }
      return errors;
    },
  },
  mounted() {
    this.getRelationship();
    this.getCountryList();
  },
  validations() {
    return {
      countrySelected: {
        required,
      },
      SelectedRelationship: {
        required,
      },
      // stateSelected: {
      //   required,
      // },
      // citySelected: {
      //   required: requiredIf(function () {
      //     if (this.haveCities) return true;
      //     else return false;
      //   }),
      // },
      // cityValue: {
      //   required: requiredIf(function () {
      //     if (!this.haveCities) return true;
      //     else return false;
      //   }),
      //   alpha,
      // },
      beneficiaryReqBody: {
        full_name: {
          required,
          alpha,
        },

        id_no: {
          // required,
          alphaNum,
        },

        postal_code: {
          // required,
          numeric,
        },
        address: {
          required,
          addresslen,
        },
      },
    };
  },
  methods: {
    ValidateDateofIncop(value) {
      console.log("value", value);
      let presentDay = new Date().toJSON().slice(0, 10);
      console.log("presentDay", presentDay);
      if (value > presentDay) {
        this.doistate = true;
      } else {
        this.doistate = false;
      }
    },
    onClickCancel() {
      this.$router.push("/transaction/3");
    },
    onChangeNumber(e) {
      this.isValidNumber = e.isValid;
    },
    onClickBack() {
      // this.$emit("onClickBack");
      this.$router.push({ name: "Beneficiary" });
    },
    onClickNext() {
      this.beneficiaryReqBody.date_of_incorporation = null;
      console.log(
        `beneficiaryReqBody: ${JSON.stringify(this.beneficiaryReqBody)}`
      );
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit("onClickNext", this.beneficiaryReqBody);
    },
    async getRelationship() {
      try {
        let responseData = await commonService.getDropdownListUser({
          parameter_code: "REL",
          user_type: "BUS",
        });
        if (responseData && responseData.data.status_code == 200) {
          this.relation_options = responseData.data.data.map((i) => {
            return {
              txt: i.value,
              val: {
                name: i.value,
                code: i.value_code,
              },
            };
          });

          //-----------mapping values while updating Beneficiary------------//
          if (this.$route.query.Remittee_ID) {
            this.SelectedRelationship = {
              name: this.recieverDetails.relationship_code.value,
              code: this.recieverDetails.relationship_code.value_code,
            };
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCountryList() {
      this.loader = true;
      const responseData = await commonService.getBeneficiaryCountryList();
      this.country_options = responseData.data.data.map((i) => {
        return {
          val: {
            iso3: i.iso_alpha3,
            name: i.country_name,
          },
          txt: i.country_name,
        };
      });
      responseData.data.data.map((i) => {
        this.countryobjs.push(i);
        if (
          i.iso_alpha3 === this.$store.state.paymodeCountry.country.country_code
        ) {
          this.countrySelected = {
            iso3: i.iso_alpha3,
            name: i.country_name,
          };
          this.onChangeCountry({
            iso3: i.iso_alpha3,
          });
        }
      });
      this.loader = false;
    },
    async onChangeCountry(country) {
      this.loader = true;
      try {
        const responseData = await commonService.getStateByIso3(country.iso3);
        this.state_options = responseData.data.data.map((i) => {
          return {
            val: {
              state_code: i.state_code,
              state_name: i.name,
            },
            txt: i.name,
          };
        });
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.log("error", error);
      }
    },
    async onChangeState(state) {
      this.loader = true;
      let id = {
        state_code: state.state_code,
        country_code: this.beneficiaryReqBody.country_code,
      };
      try {
        const responseData = await commonService.getCityByIso3(id);
        this.city_options = responseData.data.data.map((i) => {
          return {
            val: {
              id: i.id,
              name: i.name,
            },
            txt: i.name,
          };
        });
        this.loader = false;
        if (this.city_options.length === 0) {
          this.loader = false;
          this.haveCities = false;
        } else {
          this.loader = false;
          // this.haveCities = true;
          console.log("have list");
        }
      } catch (error) {
        this.loader = false;
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-name {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
</style>
