<template>
  <div class="page-body">
    <TitleBar :title="$t('fundTransfer')" />
    <v-container>
      <v-alert
        v-if="alertMsg.status"
        :timeout="alertMsg.timeout"
        :color="alertMsg.color"
        :type="alertMsg.type"
        dismissible
      >
        {{ alertMsg.text }}
      </v-alert>
      <v-row class="mt-0 d-flex justify-content-center" no-gutters>
        <v-col col sm="12" md="12" lg="12">
          <v-stepper class="transaction_main" v-model="currentStep">
            <v-stepper-header>
              <v-stepper-step :complete="currentStep > 1" step="1">{{
                $t("amount")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 2" step="2">
                {{ $t("you") }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 3" step="3">
                {{ $t("recipient") }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 4" step="4">
                {{ $t("transactionDetails") }}</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 5" step="5">{{
                $t("review&pay")
              }}</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="pt-0">
                <AmountSection
                  @onNextClick="onClickNext"
                  @isBeneSummary="isBeneSummary"
                  :transactionDetails="
                    this.transaction_details.transactionDetails
                  "
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <account-type
                  :accountDetails="this.transaction_details.accountDetails"
                  @onAccountNext="onClickNext"
                  @onClickBack="onClickBack"
                >
                </account-type>
              </v-stepper-content>
              <v-stepper-content step="3">
                <Recipient
                  :beneficiaryData="this.transaction_details.reciepient"
                  :serviceCharge="{
                    selected_service_charge:
                      this.transaction_details.transactionDetails &&
                      this.transaction_details.transactionDetails
                        .selected_service_charge,
                    selected_country:
                      this.transaction_details.transactionDetails &&
                      this.transaction_details.transactionDetails
                        .selected_country,
                    country_name:
                      this.transaction_details.transactionDetails &&
                      this.transaction_details.transactionDetails.country_name,
                  }"
                  @onNextClick="onClickNext"
                  @onClickBack="onClickBack"
                />
              </v-stepper-content>
              <v-stepper-content step="4">
                <account-section
                  :self="
                    this.transaction_details.acountDetails &&
                    this.transaction_details.acountDetails.self
                  "
                  :paymentDetails="this.transaction_details.paymentDetails"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                ></account-section>
              </v-stepper-content>
              <v-stepper-content step="5">
                <Summary
                  v-if="currentStep === 5"
                  :transactionDetails="transaction_details"
                  @onClickBack="onClickBack"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TitleBar from "@/components/navigations/TitleBar.vue";
import remitteService from "@/services/RemitteService/remitteService.js";
import AccountType from "@/components/SendMoney/AccountType/index.vue";
import Recipient from "@/components/SendMoney/Recipient/index.vue";
import AccountSection from "@/components/SendMoney/AccountSection/index.vue";
import AmountSection from "@/components/SendMoney/AmountSection/index.vue";
import Summary from "@/components/SendMoney/Summary/index.vue";
import transactionService from "@/services/TransactionService/transactionService";
import { EventBus } from "../../plugins/events.js";
export default {
  name: "SendMoney",
  components: {
    Summary,
    // NavBar,
    TitleBar,
    // NavBarLeft,
    AccountType,
    Recipient,
    // AddressSection,
    AccountSection,
    AmountSection,
  },
  data() {
    return {
      filter: null,
      on_behalf: false,
      tabIndex: 1,
      currentStep: 1,
      selected: [],
      steps: [
        { text: this.$t("amount") },
        { text: this.$t("you") },
        { text: this.$t("receipient") },
        { text: this.$t("transactionDetails") },
        { text: this.$t("reviewPay") },
        { text: this.$t("accountType") },
        { text: this.$t("basicDetails") },
        { text: this.$t("address") },
        { text: this.$t("bankDetails") },
      ],
      transaction_details: {},
      loader: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      beneficiary: {
        basicDetails: {},
        addressInfo: {},
        accountDetails: {},
        type: "IND",
        relationship: "546",
        pin_code: "",
        adline1: "",
        adline2: "",
        fname: "",
        lname: "",
        name: "",
        phone: "",
        email: "",
        address: "",
        country: {},
        state: {},
        city: {},
        account: "",
        ifsc: "",
        confirmAccount: "",
        countryCod: "",
        remitteId: "",
      },
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "",
        type: "info",
      },
      is_self_beneficiary: null,
      self_bene_details: [],
      self_bene_remitte_id: null,
      reciepientDetails: {},
      showVals: null,
    };
  },
  created() {
    this.is_self_beneficiary = JSON.parse(
      sessionStorage.getItem("is_self_beneficiary")
    );
    if (this.is_self_beneficiary) {
      this.getBenficiary();
    }
  },
  mounted() {
    this.$emit("getProfile");
    const prevPath = sessionStorage.getItem("prevPath");
    //validate redirect from home page
    console.log("from store", this.$store.state.data);
    if (
      prevPath === "/home" &&
      this.$route.params &&
      this.$route.params.stepCount
    ) {
      this.getFieldDetails(this.$route.params.stepCount);
    } else if (this.$route.params?.stepCount <= 5)
      this.currentStep = this.$route.params?.stepCount || 1;

    EventBus.$on("selectedBeneData", (data) => {
      this.getDetails(data);
      console.log("isBene", data);
    });
  },
  methods: {
    async getBenficiary() {
      try {
        const responseData = await remitteService.getremitteeList();
        console.log("responseData.data", responseData.data);
        if (responseData.data.status_code === 200) {
          this.self_bene_details = responseData.data.data;
          this.self_bene_remitte_id = responseData.data.data[0].receiver_id;
        }
      } catch (e) {
        console.error(e);
      }
    },
    getDetails(data) {
      this.data = data;
      if (this.data.Remittee_ID) {
        this.showVals = true;
      }
      console.log("this.data from method: ", this.data);
    },
    isBeneSummary() {
      this.value = false;
    },
    async getFieldDetails(txn_id) {
      const responseData = await transactionService.getTransactionFeildDetails(
        txn_id
      );
      let result = responseData.data?.[0];
      this.transaction_details = {
        transactionDetails: {
          amountSection: {
            sendAmount: result.sending_amount,
            paymentMode: result.payment_mode_cd,
            finalSendAmount: result.total_coll_amount,
          },
          target_currency: result.pay_crncy_cd,
          base_currency: result.coll_crncy_cd,
        },
        accountDetails: {
          benficiaryDetails: {
            fname: result.onbehalf_first_name,
            lname: result.onbehalf_last_name,
            phone: result.onbehal_phone,
            email: result.onbehalf_email,
            nationality: result.onbehalf_nationality,
            postalCode: result.onbehal_postal_code,
            occupation: result.onbehal_occupation,
            dob: result.onbehalf_date_of_birth,
            gender: result.onbehalf_gender,
            id_type: result.onbehalf_id_type,
            issue_date: result.onbehalf_id_issue_date,
            issue_location: result.onbehalf_id_issue_location,
            id_number: result.onbehalf_id_number,
            address: result.onbehal_address,
            expiry_date: result.onbehalf_id_expiry_date,
          },
          isSelf: result.is_self,
        },
        reciepient: {
          selected_remittee_id: result.remittee_id,
        },
        paymentDetails: {
          payment_type: "FPX",
          reason: result.reason_of_remittance_cd,
          sourceoffunds: result.source_of_fund_cd,
          remarks: "",
          bank_advice: [],
          business_Invoice: [],
        },
      };
      await this.$store.dispatch(
        "Transactional/setTransactionDetails",
        this.transaction_details
      );
    },
    select_beneficiary(data) {
      this.transaction_details.beneficiary = data;
      this.tabIndex++;
    },
    onClickNext: async function (valueObject = {}) {
      console.log("valueObject", valueObject);
      let selctedBeneData = this.$store.state.data;
      // if Bene comes from bene List (start transaction)
      if (selctedBeneData.receiver_id && this.currentStep == 2) {
        const Tempdata = valueObject;
        valueObject = {
          accountDetails: Tempdata.accountDetails,
          reciepient: selctedBeneData,
        };
        console.log("valueObject1", valueObject);
      }
      // if is_self benficiary means need to direct to step 4(No beneficiary)
      if (this.is_self_beneficiary && this.currentStep == 1) {
        if (this.self_bene_remitte_id && this.self_bene_details.length > 0) {
          valueObject = {
            transactionDetails: valueObject.transactionDetails,
            accountDetails: {
              isSelf: true,
            },
            reciepient: this.self_bene_details[0],
          };
        }
      }
      // mapping all data from all sub tabs componenets in one object
      this.transaction_details = {
        ...((this.$store.state.Transactional &&
          this.$store.state.Transactional.transactionDetails) ||
          {}),
        ...valueObject,
      };
      console.log("ddsd", this.transaction_details.transactionDetails);

      await this.$store.dispatch("Transactional/setTransactionDetails", {
        ...this.transaction_details,
        ...valueObject,
      });
      // if Bene comes from Bene list then directly get into tab 4
      if (selctedBeneData.receiver_id && this.currentStep == 2) {
        console.log(
          "consider data",
          this.transaction_details.transactionDetails.country_name
        );
        if (
          this.transaction_details.transactionDetails &&
          this.transaction_details.transactionDetails.country_name ===
            selctedBeneData.country_code.iso_alpha3
        ) {
          this.currentStep = 4;
        } else {
          this.currentStep++;
        }
      } else if (
        this.is_self_beneficiary &&
        this.currentStep == 1 &&
        this.self_bene_remitte_id
      ) {
        this.currentStep = 4;
      } else {
        this.currentStep++;
      }
    },
    onClickBack: function () {
      this.transaction_details =
        (this.$store.state.Transactional &&
          this.$store.state.Transactional.transactionDetails) ||
        {};
      let selctedBeneData = this.$store.state.data;
      if (selctedBeneData.receiver_id && this.currentStep == 4) {
        if (
          this.transaction_details.transactionDetails &&
          this.transaction_details.transactionDetails.country_name ===
            selctedBeneData.country_code.iso_alpha3
        ) {
          this.currentStep = 2;
        } else {
          this.currentStep--;
        }
      } else if (
        this.is_self_beneficiary &&
        this.currentStep == 4 &&
        this.self_bene_remitte_id
      ) {
        this.currentStep = 1;
      } else {
        this.currentStep--;
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onBehalf() {
      this.on_behalf = true;
    },
    notOnBehalf() {
      this.on_behalf = false;
    },
    ConfirmAndPay() {
      this.$router.push({
        name: "PaymentGateway",
        params: { transaction_details: this.transaction_details },
      });
    },
    onSelectAccountType(type) {
      this.beneficiary.type = type;
    },
    onClickNextSecondSection() {
      if (this.data.Remittee_ID && this.currentStep == 2) {
        // alert("hi");
        this.currentStep + 2;
      } else {
        this.currentStep++;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/global.scss";
.operation-padding {
  padding-top: 4px;
}
.converted {
  color: $txt;
  font-weight: 600;
}
.logo_img {
  height: 120px;
}
::v-deep ul {
  list-style-type: none !important;
}

::v-deep h4 {
  color: $txt;
  text-align: center;
}
::v-deep .btn-primary {
  background-color: $txt !important;
  border-color: $txt !important;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid #f00 !important;
    color: $dimgrey;
    background: $white;
  }
}
.form-group {
  margin-bottom: 0 !important;
}
::v-deep th {
  color: $txt !important;
  font-size: 14px;
  font-weight: 600;
}
.operations {
  height: 20px;
  width: 20px;
  position: absolute;
  border-radius: 10px;
  background: #d3d3d3;
  text-align: center;
  font-size: 20px;
  margin-left: -15px;
  line-height: 15px;
  bottom: 15px;
}
.list-group-flush {
  border-left: 1px solid $txt;
  margin-left: 25px;
}
::v-deep .btn-light {
  border: 1px solid #d3d3d3 !important;
}
::v-deep ul {
  list-style-type: none !important;
}
::v-deep label {
  margin-bottom: 0 !important;
}
li {
  padding: 7px;
}
::v-deep .btn-light {
  border: 1px solid $lightgrey !important;
}
.floating-placeholder-name {
  color: red;
  margin-top: -12px;
  font-size: 14px;
  text-align: -webkit-left;
}
.color-error {
  background-color: rgb(240, 50, 50);
}
.card {
  border: none;
}
</style>
