<template>
  <div class="page-body">
    <v-container>
      <v-alert
        v-if="alertMsg.status"
        :timeout="alertMsg.timeout"
        :color="alertMsg.color"
        :type="alertMsg.type"
        dismissible
      >
        {{ alertMsg.text }}
      </v-alert>
      <v-row class="mt-5 d-flex justify-content-center">
        <v-col col sm="12" md="12" lg="12">
          <v-stepper v-model="currentStep">
            <div class="d-flex justify-space-between color pa-2">
              <div>
                <span class="text-h6 ms-2 mt-2">{{
                  $t("addBeneficiary")
                }}</span>
              </div>
              <div>
                <v-btn fab small color="white" @click="closeBusDialog">
                  <i class="fa-solid fa-xmark"></i>
                </v-btn>
              </div>
            </div>
            <v-stepper-header>
              <v-stepper-step :complete="currentStep > 1" step="1">
                {{ $t("company_information") }}</v-stepper-step
              >
              <!-- <v-divider></v-divider> -->
              <!-- <v-stepper-step :complete="currentStep > 2" step="2">
                {{
                  $t("representative_information_beneficary")
                }}</v-stepper-step
              > -->
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 2" step="2">
                {{ $t("account_details_beneficary") }}</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 3" step="3">
                {{ $t("summary_bus") }}</v-stepper-step
              >
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <company-details
                  :isTrans="isTrans"
                  :dialogisOpen="dialogisOpen"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                  @onCountrySelect="country_code = $event"
                />
              </v-stepper-content>

              <!-- <v-stepper-content step="2">
                <representative-info
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :bene_country_name="bene_country_name"
                  :loader="loader"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                ></representative-info>
              </v-stepper-content> -->
              <v-stepper-content step="2">
                <account-section
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :loader="loader"
                  :country_code="country_code"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                ></account-section>
              </v-stepper-content>
              <v-stepper-content step="3">
                <Summary
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  @onClickNext="onCreateBeneficiary"
                  @onClickBack="onClickBack"
                ></Summary>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import TitleBar from "@/components/navigations/TitleBar.vue";
import CompanyDetails from "@/views/beneficiary/BuscompanyDetails.vue";
import AccountSection from "@/components/BeneficiaryBus/AccountSection/index.vue";
// import AddressSection from "@/components/BeneficiaryBus/AddressSection/index.vue";
// import RepresentativeInfo from "@/components/BeneficiaryBus/RepresentativeInfo/index.vue";
import Summary from "@/components/BeneficiaryBus/Summary/index.vue";
import remitteService from "@/services/RemitteService/remitteService";
import alerts from "@/mixins/alerts";
// import axios from "axios";

export default {
  name: "BeneficiaryBus",
  components: {
    // TitleBar,
    CompanyDetails,
    // AddressSection,
    AccountSection,
    // RepresentativeInfo,
    Summary,
  },
  mixins: [alerts],
  props: {
    showBusDialog: {
      type: Boolean,
    },
  },
  watch: {
    showBusDialog: {
      handler(val) {
        if (val) {
          this.dialogisOpen = val;
        }
      },
    },
  },
  data() {
    return {
      steps: [
        { text: this.$t("accountType") },
        { text: this.$t("basicDetails") },
        { text: this.$t("address") },
        { text: this.$t("bankDetails") },
      ],
      dialogisOpen: false,
      isTrans: "",
      currentStep: 1,
      loader: false,
      bene_country_name: "",
      beneficiaryReqBody: {
        full_name: "",
        id_no: "",
        nature_of_business: "",
        address: "",
        country_code: "",
        Remittee_country: "",
        state_code: "",
        Remittee_state: "",
        city_code: null,
        Remittee_city: "",
        postal_code: "",
        first_name: "",
        last_name: "",
        dob: null,
        designation: "",
        phone_number: "",
        email_id: "",
        payment_code: "B",
        currency_code: "",
        phone_number_cty_code: "",
        relationship_code:"",
        relationship_name:"",
        location_id: "",
        location_name: "",
        bank_name: "",
        branch_code: "",
        bank_account_no: "",
      },
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      country_code: "",
    };
  },
  mounted() {
    this.$emit("getProfile");
    this.isTrans = this.$route.params?.isTran || "";
    if (this.$route.query.Remittee_ID)
      this.getRemitteDetailByID(this.$route.query.Remittee_ID);
  },
  methods: {
    closeBusDialog() {
      this.$emit("closeBusDialog", false);
      this.$store.state.showDialog = false;
      this.currentStep = 1;
      //   window.location.reload();
    },
    onClickNext() {
      this.currentStep++;
      this.bene_country_name = this.beneficiaryReqBody.phone_number_cty_code;
    },
    onClickBack() {
      this.currentStep--;
    },
    async onCreateBeneficiary() {
      console.log("beneficiaryReqBody", this.beneficiaryReqBody);

      try {
        this.loader = true;
        let responseData;
        // if (this.$route.query.Remittee_ID) {
        //   responseData = await this.$store.dispatch(
        //     "Remittee/updateBusRemittee",
        //     Object.assign(this.beneficiaryReqBody, {
        //       remitteID: this.$route.query.Remittee_ID,
        //     })
        //   );
        // } else {
        //   responseData = await this.$store.dispatch(
        //     "Remittee/createBusRemitte",
        //     this.beneficiaryReqBody
        //   );
        // }
        if (this.$route.query.Remittee_ID) {
          responseData = await remitteService.updateBusRemittee(
            Object.assign(this.beneficiaryReqBody, {
              remitteID: this.$route.query.Remittee_ID,
            })
          );
        } else {
          responseData = await remitteService.createBusRemitte(
            this.beneficiaryReqBody
          );
        }

        if (responseData && responseData.data.status_code == 200) {
          this.loader = false;
          // this.alertMsg.status = true;
          // this.alertMsg.type = "success";
          // this.alertMsg.text = responseData.data.message;
          // this.alertMsg.color = "green";
          this.showAlertSuccess(responseData.data.message);
          this.$emit("closeBusDialog", false);
          this.currentStep = 1;
          this.$store.state.CreatedBeneDetails = this.beneficiaryReqBody;
          this.beneficiaryReqBody = {
            full_name: "",
            id_no: "",
            nature_of_business: "",
            address: "",
            country_code: "",
            Remittee_country: "",
            state_code: "",
            Remittee_state: "",
            city_code: null,
            Remittee_city: "",
            postal_code: "",
            first_name: "",
            last_name: "",
            dob: null,
            designation: "",
            phone_number: "",
            email_id: "",
            payment_code: "B",
            currency_code: "",
            phone_number_cty_code: "",
            location_id: "",
            location_name: "",
            bank_name: "",
            branch_code: "",
            bank_account_no: "",
          };
          // this.alertMsg.status = false;
        } else {
          this.loader = false;
          // this.alertMsg.status = true;
          // this.alertMsg.type = "error";
          // this.alertMsg.text = responseData.data.message;
          // this.alertMsg.color = "warning";
          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        this.loader = false;
        // this.alertMsg.status = true;
        // this.alertMsg.type = "error";
        // this.alertMsg.text =
        //   "Error: Something went wrong.Please try again later.";
        // this.alertMsg.color = "warning";
        this.showAlertError('Error: Something went wrong.Please try again later.');
      }
    },
    async getRemitteDetailByID(remitteeID) {
      // const token = sessionStorage.getItem("access_token");
      try {
        // const responseData = await axios.get(
        //   `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene/get-receiver-by-id/${remitteeID}`,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${token}`,
        //       accept: "application/json",
        //     },
        //   }
        // );
        const responseData = await await remitteService.getRemitteDetailByID(remitteeID);
        console.log("getRemitteDetailByID: ", responseData.data);
        if (responseData.data.status_code === 200) {
          const remiteeResult = responseData.data.data[0];
          Object.assign(this.beneficiaryReqBody, {
            registration_type_cd: remiteeResult.registration_type_cd,
            first_name: remiteeResult.first_name,
            last_name: remiteeResult.last_name,
            middle_name: remiteeResult.middle_name,
            relation_with_Remitter_cd: remiteeResult.relation_with_Remitter_cd,
            relation_with_Remitter_others:
              remiteeResult.relation_with_Remitter_others,
            phone_no: remiteeResult.phone_no,
            email_id: remiteeResult.email_id,
            id_type: remiteeResult.id_type,
            id_number: remiteeResult.id_number,
            issue_date: remiteeResult.issue_date,
            expiry_date: remiteeResult.expiry_date,
            issue_location: remiteeResult.issue_location,
            Remittee_dob: remiteeResult.Remittee_dob,
            address: remiteeResult.address,
            postal_cd: remiteeResult.postal_cd,
            Remittee_city: remiteeResult.Remittee_city,
            Remittee_state: remiteeResult.Remittee_state,
            Remittee_country: remiteeResult.Remittee_country,
            province: remiteeResult.province,
            payment_mode: remiteeResult.payment_mode,
            bank_ac_no: remiteeResult.bank_ac_no,
            bank_cd: remiteeResult.bank_branch_cd,
            city_Code: remiteeResult.city_Code,
            State_Code: remiteeResult.State_Code,
            Country_code: remiteeResult.Country_code,
            city_id: remiteeResult.city_id,
            state_id: remiteeResult.state_id,
            country_id: remiteeResult.country_id,
            registered_business_name: remiteeResult.registered_business_name,
            buisiness_registration_number:
              remiteeResult.buisiness_registration_number,
            date_of_incorporation: remiteeResult.date_of_incorporation,
            nature_of_business: remiteeResult.nature_of_business,
            business_contact_number: remiteeResult.business_contact_number,
            business_email: remiteeResult.business_email,
            bank_name: remiteeResult.bank_name,
            bank_branch_cd: remiteeResult.bank_branch_cd,
            occupation_text: remiteeResult.occupation_text,
            designation: remiteeResult.designation,
            payment_location: remiteeResult.payment_location,
            gender: remiteeResult.gender,
            phone_number_cty: remiteeResult.phone_number_cty,
          });
          // console.log("***///", this.beneficiaryReqBody)
        }
      } catch (e) {
        return e;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
::v-deep ul {
  list-style-type: none !important;
}
::v-deep h4 {
  color: $txt;
  text-align: center;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid red !important;
    color: $dimgrey;
    background: $white;
  }
}
.card {
  border: none;
}
::v-deep label {
  color: $lightslategrey !important;
  margin-bottom: 0 !important;
}
::v-deep .btn-light {
  border: 1px solid $lightgrey !important;
}
.floating-placeholder-name {
  color: red;
  margin-top: -12px;
  font-size: 14px;
  text-align: -webkit-left;
}
.color-error {
  background-color: rgb(240, 50, 50);
}
.color {
  background-color: rgb(222, 220, 220);
}
.fa-xmark {
  font-size: 18px;
}
</style>
