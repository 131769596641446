<template>
  <div class="page-body">
    <v-container v-if="!showBusUser">
      <v-alert
        v-if="alertMsg.status"
        :timeout="alertMsg.timeout"
        :color="alertMsg.color"
        :type="alertMsg.type"
        dismissible
      >
        {{ alertMsg.text }}
      </v-alert>
      <v-row class="mt-5 d-flex justify-content-center ben_main">
        <v-col col sm="12" md="12" lg="12">
          <v-stepper v-model="currentStep">
            <div class="d-flex justify-space-between color pa-2">
              <div>
                <span class="text-h6 ms-2 mt-2">{{ $t("addBeneficiary") }}</span>
              </div>
              <div>
                <v-btn fab small color="white" @click="closeDialog">
                  <i class="fa-solid fa-xmark"></i>
                </v-btn>
              </div>
            </div>
            <v-stepper-header>
              <v-stepper-step :complete="currentStep > 1" step="1">{{
                $t("bankDetails")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 2" step="2">{{
                $t("basicDetails")
              }}</v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 3" step="3">{{
                $t("summary")
              }}</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <account-type
                  :isTrans="isTrans"
                  :policy_number="policy_number"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <basic-details
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :bene_country="bene_country_name"
                  :payment_method_selected="payment_method_selected"
                  @onClickNext="onClickNext"
                  @onClickBack="onClickBack"
                />
              </v-stepper-content>

              <v-stepper-content step="3">
                <Summary
                  :isTrans="isTrans"
                  :beneficiaryReqBody="beneficiaryReqBody"
                  :loader="loader"
                  @onClickNext="onCreateBeneficiary"
                  @onClickBack="onClickBack"
                ></Summary>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import BenificiaryType from "@/components/BeneficiaryPage/BenificiaryType/index.vue";
import AccountType from "@/views/beneficiary/accounttype.vue";
import BasicDetails from "@/components/BeneficiaryPage/BasicDetails/index.vue";
// import AddressSection from "@/components/BeneficiaryPage/AddressSection/index.vue";
import Summary from "@/components/BeneficiaryPage/Summary/index.vue";
import remitteService from "@/services/RemitteService/remitteService";
import alerts from "@/mixins/alerts";
// import axios from "axios";
import { EventBus } from "../../plugins/events.js";
export default {
  name: "Beneficiary",
  components: {
    // BenificiaryType,
    AccountType,
    BasicDetails,
    // AddressSection,
    Summary,
  },
  mixins: [alerts],
  data() {
    return {
      isTrans: "",
      steps: [
        { text: this.$t("accountType") },
        { text: this.$t("basicDetails") },
        // { text: this.$t("address") },
        { text: this.$t("bankDetails") },
      ],
      currentStep: 1,
      loader: false,
      beneficiaryReqBody: {
        rec_type: "IND",
        country_name: "",
        country_code: "",
        currency_code: "",
        payment_code: "",
        branch_code: "",
        branch_id: 0,
        bank_id: 0,
        bank_name: "",
        bank_account_no: null,
        location_id: "",
        location_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        last_name2: "",
        relationship_code: "",
        relationship_name: "",
        payment_mode_name: "",
        phone_number: "",
        mobile_number: "",
        address: "",
        state_code: "",
        state_name: "",
        district_code: "",
        city_code: "",
        city_name: "",
        postal_code: "",
        policy_number: "",
        id_no: "",
        id_type: "",
      },

      bene_country_name: "",
      payment_method_selected: "",
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      createdBeneficiary: null,
      showBusUser: false,
    };
  },
  props: {
    policy_number: {
      type: String,
    },
  },
  watch: {
    "beneficiaryReqBody.paying_cntry_cd"(val) {
      this.bene_country_details.code = this.beneficiaryReqBody.paying_cntry_cd;

      this.bene_country_name = this.beneficiaryReqBody.paying_cntry_name;

      console.log(val);
    },
  },
  mounted() {
    this.createdBeneficiary = this.showDialog;
    this.$emit("getProfile");
    this.isTrans = this.$route.params?.isTran || "";

    console.log("remitteId ", this.$route);
    // This will work in edit mode.
    if (this.$route.query.Remittee_ID)
      this.getRemitteDetailByID(this.$route.query.Remittee_ID);
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
      this.$store.state.showDialog = false;
      this.currentStep = 1;
      //   window.location.reload();
    },
    onClickNext() {
      this.currentStep++;
      console.log("this.beneficiaryReqBody", this.beneficiaryReqBody);
      this.bene_country_name = this.beneficiaryReqBody.country_name;
      this.payment_method_selected = this.beneficiaryReqBody.payment_mode;
    },
    onClickBack() {
      this.currentStep--;
    },
    onSelectAccountType() {
      // console.log("beneficiaryReqBody", this.beneficiaryReqBody);
      if (this.beneficiaryReqBody.registration_type_cd == "BUS") {
        this.showBusUser = true;
        // if (this.isTrans == "true") this.$router.push("/beneficiary-bus/true");
        // else this.$router.push({ name: "BeneficiaryBus" });
      } else this.currentStep++;
    },
    async onCreateBeneficiary() {
      // alert("yes im in ");
      EventBus.$emit("serviceCharge", {
        country_name: this.beneficiaryReqBody.paying_cntry_cd,
        paymentmode: this.beneficiaryReqBody.payment_mode_cd,
      });
      try {
        this.loader = true;
        let responseData;
        // if (this.$route.query.Remittee_ID) {
        //   responseData = await this.$store.dispatch(
        //     "Remittee/updateRemittee",
        //     Object.assign(this.beneficiaryReqBody, {
        //       remitteID: this.$route.query.Remittee_ID,
        //     })
        //   );
        // } else {
        //   responseData = await this.$store.dispatch(
        //     "Remittee/createRemitte",
        //     this.beneficiaryReqBody
        //   );
        // }
        if (this.$route.query.Remittee_ID) {
          responseData = await remitteService.updateRemittee(
            Object.assign(this.beneficiaryReqBody, {
              remitteID: this.$route.query.Remittee_ID,
            })
          );
        } else {
          responseData = await remitteService.createRemittee(this.beneficiaryReqBody);
        }

        if (responseData && responseData.data.status_code == 200) {
          this.loader = false;
          // this.alertMsg.status = true;
          // this.alertMsg.type = "success";
          // this.alertMsg.text = responseData.data.message;
          // this.alertMsg.color = "green";
          this.$emit("closeDialog", false);
          this.$store.state.CreatedBeneDetails = this.beneficiaryReqBody;
          this.currentStep = 1;
          this.beneficiaryReqBody = {
            rec_type: "IND",
            country_name: "India",
            country_code: "",
            currency_code: "",
            payment_code: "",
            branch_code: "",
            branch_id: 0,
            bank_id: 0,
            bank_name: "",
            bank_account_no: null,
            location_id: "",
            location_name: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            last_name2: "",
            relationship_code: "",
            relationship_name: "",
            phone_number: "",
            mobile_number: "",
            address: "",
            state_code: "",
            state_name: "",
            district_code: "",
            city_code: "",
            city_name: "",
            postal_code: "",
          };
          // this.alertMsg.status = true;
          this.showAlertSuccess(responseData.data.message);
        } else {
          this.loader = false;
          this.showAlertError(responseData.data.message);
          // this.alertMsg.status = true;
          // this.alertMsg.type = "error";
          // this.alertMsg.text = responseData.data.message;
          // this.alertMsg.color = "warning";
        }
      } catch (error) {
        this.loader = false;
        this.showAlertError("Something went wrong.Please try again later.");
        // this.alertMsg.status = true;
        // this.alertMsg.type = "error";
        // this.alertMsg.text =
        //   "Error: Something went wrong.Please try again later.";
        // this.alertMsg.color = "warning";
      }
    },
    async getRemitteDetailByID(remitteeID) {
      // const token = sessionStorage.getItem("access_token");
      try {
        // const responseData = await axios.get(
        //   `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene/get-receiver-by-id/${remitteeID}`,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${token}`,
        //       accept: "application/json",
        //     },
        //   }
        // );
        const responseData = await await remitteService.getRemitteDetailByID(remitteeID);
        console.log("getRemitteDetailByID: ", responseData.data);
        if (responseData.data.status_code === 200) {
          const remiteeResult = responseData.data.data[0];

          Object.assign(this.beneficiaryReqBody, {
            registration_type_cd: remiteeResult.registration_type_cd,
            first_name: remiteeResult.first_name,
            last_name: remiteeResult.last_name,
            middle_name: remiteeResult.middle_name,
            relation_with_Remitter_cd: remiteeResult.relation_with_Remitter_cd,
            relation_with_Remitter_others: remiteeResult.relation_with_Remitter_others,
            phone_no: remiteeResult.phone_no,
            email_id: remiteeResult.email_id,
            id_type: remiteeResult.id_type,
            id_number: remiteeResult.id_number,
            issue_date: remiteeResult.issue_date,
            expiry_date: remiteeResult.expiry_date,
            issue_location: remiteeResult.issue_location,
            address: remiteeResult.address,
            postal_cd: remiteeResult.postal_cd,
            Remittee_city: remiteeResult.Remittee_city,
            Remittee_state: remiteeResult.Remittee_state,
            Remittee_country: remiteeResult.Remittee_country,
            province: remiteeResult.province,
            payment_mode: remiteeResult.payment_mode,
            bank_ac_no: remiteeResult.bank_ac_no,
            bank_cd: remiteeResult.bank_branch_cd,
            city_Code: remiteeResult.city_Code,
            State_Code: remiteeResult.State_Code,
            Country_code: remiteeResult.Country_code,
            city_id: remiteeResult.city_id,
            state_id: remiteeResult.state_id,
            country_id: remiteeResult.country_id,
            registered_business_name: remiteeResult.registered_business_name,
            buisiness_registration_number: remiteeResult.buisiness_registration_number,
            date_of_incorporation: remiteeResult.date_of_incorporation,
            nature_of_business: remiteeResult.nature_of_business,
            business_contact_number: remiteeResult.business_contact_number,
            business_email: remiteeResult.business_email,
            bank_name: remiteeResult.bank_name,
            bank_branch_cd: remiteeResult.bank_branch_cd,
            occupation_text: remiteeResult.occupation_text,
            designation: remiteeResult.designation,
            payment_location: remiteeResult.payment_location,
            gender: remiteeResult.gender,
            Remittee_dob: "",
            payment_mode_cd: remiteeResult.payment_mode,
            Remittee_occup: "",
            location_id: remiteeResult.location_id,
            location_name: remiteeResult.location_name,
            pay_crncy_cd: remiteeResult.pay_crncy_cd,
            paying_cntry_cd: remiteeResult.paying_cntry_cd,
            paying_cntry_name: remiteeResult.paying_cntry_name,
            phone_number_cty: remiteeResult.phone_number_cty,
          });
        }
      } catch (e) {
        return e;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
::v-deep ul {
  list-style-type: none !important;
}
::v-deep h4 {
  color: $txt;
  text-align: center;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid red !important;
    color: $dimgrey;
    background: $white;
  }
}
.card {
  border: none;
}
::v-deep label {
  color: $lightslategrey !important;
  margin-bottom: 0 !important;
}
::v-deep .btn-light {
  border: 1px solid $lightgrey !important;
}
.floating-placeholder-name {
  color: red;
  margin-top: -12px;
  font-size: 14px;
  text-align: -webkit-left;
}
.color-error {
  background-color: rgb(240, 50, 50);
}
.color {
  background-color: rgb(222, 220, 220);
}
.fa-xmark {
  font-size: 18px;
}
</style>
