var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"w-75 m-auto account-main-div"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-card',{staticClass:"text-center rounded-xl m-4",class:_vm.isSelf
          ? 'border-primary account-selection'
          : 'border-light account-selection',attrs:{"elevation":"4","rounded":"xl","tile":""},on:{"click":_vm.selfSelected}},[_c('div',{staticClass:"p-4 h-100",staticStyle:{"position":"relative"}},[_c('h6',{class:_vm.isSelf
              ? 'text-primary-color font-weight-bold'
              : 'text-secondary font-weight-normal'},[_vm._v(" "+_vm._s(_vm.$t("Self"))+" ")]),_c('br'),_c('v-icon',{staticClass:"account-icons",style:(_vm.isSelf
              ? 'border: 1px solid #ff6b00;'
              : 'border: 1px solid #6c757d;'),attrs:{"color":_vm.isSelf ? 'primary' : 'grey',"large":""}},[_vm._v(" mdi-account-tie ")]),_c('br'),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelf),expression:"isSelf"}],staticClass:"float-right account-icons-check",attrs:{"color":"primary","large":""}},[_vm._v(" mdi-check-circle ")])],1)]),_c('v-card',{staticClass:"text-center rounded-xl m-4",class:_vm.isSomeone
          ? 'border-primary accoun account-selection'
          : 'border-light account-selection',attrs:{"rounded":"xl","tile":""},on:{"click":_vm.someoneSelected}},[_c('div',{staticClass:"p-4 h-100",staticStyle:{"position":"relative"}},[_c('h6',{class:_vm.isSomeone
              ? 'text-primary-color font-weight-bold'
              : 'text-secondary font-weight-normal'},[_vm._v(" "+_vm._s(_vm.$t("On_Behalf_Of"))+" ")]),_c('br'),_c('v-icon',{staticClass:"account-icons",style:(_vm.isSomeone
              ? 'border: 1px solid #ff6b00;'
              : 'border: 1px solid #6c757d;'),attrs:{"color":_vm.isSomeone ? 'primary' : 'grey',"large":""}},[_vm._v("mdi-account-plus-outline ")]),_c('br'),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSomeone),expression:"isSomeone"}],staticClass:"float-right account-icons-check",attrs:{"color":"primary","large":""}},[_vm._v(" mdi-check-circle ")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.isSomeone)?_c('BasicDetails',{ref:"basicDetails_comp",attrs:{"beneficiaryDetails":_vm.account_user_Details}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"float-left mt-5 px-5 btn-primary-outlined text-capitalize",attrs:{"outlined":""},on:{"click":_vm.onClickBack}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("backLabel")))],1),_c('v-btn',{staticClass:"float-right mt-5 px-5 btn-primary-outlined text-capitalize",attrs:{"outlined":""},on:{"click":_vm.onClickNext}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }