<template>
  <v-card class="v-transaction-card" rounded="lg" elevation="5">
    <v-card-title class="card-heading">
      <label> {{ $t("summary") }} </label>
    </v-card-title>
    <v-row class="px-6 mb-2 custome-text">
      <v-col
        cols="6"
        class="pl-2 m-0 fixed-height-div d-flex align-items-center text-end"
      >
        <v-row no-gutters>
          <v-col lg="11" sm="8">
            <h6>{{ $t("CollectedAmount") }}</h6>
          </v-col>
          <div class="rounded-circle v-custome-icon">
            <v-icon>mdi-equal</v-icon>
          </div>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        class="pl-2 m-0 d-flex justify-content-end align-items-center"
      >
        <h6 class="ellipsis">
          {{
            Number(
              +Number(exchangeData.collectAmount || 0)
            ).toLocaleString()
          }}
        </h6>
        <h6 class="ml-2">{{ base_currency }}</h6>
        <img
          class="ml-2 mb-1 extra_space"
          width="18"
          height="18"
          src="@/assets/give-money-hand_down.svg"
        />
        <v-col lg="2" class="extra_space"> </v-col>
      </v-col>

      <v-col
        cols="6"
        class="pl-2 m-0 fixed-height-div d-flex align-items-center text-end"
      >
        <v-row no-gutters>
          <v-col lg="11" sm="8">
            <h6>{{ $t("fee") }}</h6>
          </v-col>
          <div class="rounded-circle v-custome-icon">
            <v-icon>mdi-minus</v-icon>
          </div>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        class="pl-2 m-0 d-flex fixed-height-div justify-content-end align-items-center"
      >
        <h6 class="ellipsis">
          {{
            Number(
              +Number(
                (exchangeData && exchangeData.serviceCharge) || 0
              )
            ).toLocaleString()
          }}
        </h6>
        <h6 class="ml-2">{{ base_currency }}</h6>
        <p class="ml-2 border-none fixed-width" />
        <v-col lg="2" sm="0" class="extra_space"> </v-col>
      </v-col>

      <v-col
        cols="6"
        class="pl-2 m-0 fixed-height-div d-flex align-items-center text-end"
      >
        <v-row no-gutters>
          <v-col lg="11" sm="8">
            <h6>
              {{ $t("TransferAmount") }}
            </h6>
          </v-col>
          <div class="rounded-circle v-custome-icon">
            <v-icon>mdi-equal</v-icon>
          </div>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        class="pl-2 m-0 d-flex fixed-height-div justify-content-end align-items-center"
      >
        <h6 class="ellipsis">
          {{
            Number(
              +Number(exchangeData.transferAmount || 0)
            ).toLocaleString()
          }}
        </h6>
        <h6 class="ml-2">{{ base_currency }}</h6>
        <p class="ml-2 border-none fixed-width" />
        <v-col lg="2" sm="0" class="extra_space"> </v-col>
      </v-col>

      <v-col
        cols="6"
        class="pl-2 m-0 fixed-height-div d-flex align-items-center text-end"
      >
        <v-row no-gutters>
          <v-col lg="11" sm="8">
            <h6 class="d-flex justify-content-end">
              {{ $t("guarantedRate") }}
              (1{{ base_currency }})
            </h6>
          </v-col>
          <div class="rounded-circle v-custome-icon">
            <v-icon>mdi-close</v-icon>
          </div>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        class="pl-2 m-0 d-flex fixed-height-div justify-content-end align-items-center"
      >
        <h6 class="ellipsis">
          {{
            Number(
              +Number(exchangeData.exchangeRate || 0)
            ).toLocaleString()
          }}
        </h6>
        <h6 class="ml-2">{{ target_currency }}</h6>
        <p class="ml-2 border-none fixed-width" />
        <v-col lg="2" sm="0" class="extra_space"> </v-col>
      </v-col>
      <v-col
        cols="6"
        class="pl-2 m-0 fixed-height-div d-flex align-items-center text-end"
      >
        <v-row no-gutters>
          <v-col lg="11" sm="8">
            <h6>
              {{ $t("receiveAmount") }}
            </h6>
          </v-col>
          <div class="rounded-circle v-custome-icon">
            <v-icon>mdi-equal</v-icon>
          </div>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        class="pl-2 m-0 d-flex justify-content-end align-items-center"
      >
        <h6 class="ellipsis">
          {{
            Number(
              +Number(exchangeData.payoutAmount || 0)
            ).toLocaleString()
          }}
        </h6>
        <h6 class="ml-2">{{ target_currency }}</h6>
        <img
          class="ml-2 mb-3 extra_space"
          width="18"
          height="18"
          src="@/assets/receive-money-flat_hand.svg"
        />
        <v-col lg="2" sm="0" class="extra_space"> </v-col>
      </v-col>

      <label
        class="light-gray mb-1"
        style="font-size: 12px !important; font-weight: 700"
        >* {{ $t("rateText") }}
        {{ new Date().toLocaleString() }}
      </label>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["target_currency", "exchangeData", "base_currency"],
};
</script>

<style></style>
