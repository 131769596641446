<template>
  <section class="w-75 m-auto account-main-div">
    <div class="d-flex justify-content-center">
      <v-card
        elevation="4"
        rounded="xl"
        tile
        :class="
          isSelf
            ? 'border-primary account-selection'
            : 'border-light account-selection'
        "
        class="text-center rounded-xl m-4"
        @click="selfSelected"
      >
        <div class="p-4 h-100" style="position: relative">
          <h6
            :class="
              isSelf
                ? 'text-primary-color font-weight-bold'
                : 'text-secondary font-weight-normal'
            "
          >
            {{ $t("Self") }}
          </h6>
          <br />
          <v-icon
            :color="isSelf ? 'primary' : 'grey'"
            large
            class="account-icons"
            :style="
              isSelf
                ? 'border: 1px solid #ff6b00;'
                : 'border: 1px solid #6c757d;'
            "
          >
            mdi-account-tie
          </v-icon>
          <br />

          <v-icon
            v-show="isSelf"
            color="primary"
            large
            class="float-right account-icons-check"
          >
            mdi-check-circle
          </v-icon>
        </div>
      </v-card>

      <v-card
        rounded="xl"
        tile
        :class="
          isSomeone
            ? 'border-primary accoun account-selection'
            : 'border-light account-selection'
        "
        class="text-center rounded-xl m-4"
        @click="someoneSelected"
      >
        <div class="p-4 h-100" style="position: relative">
          <h6
            :class="
              isSomeone
                ? 'text-primary-color font-weight-bold'
                : 'text-secondary font-weight-normal'
            "
          >
            {{ $t("On_Behalf_Of") }}
          </h6>
          <br />
          <v-icon
            :color="isSomeone ? 'primary' : 'grey'"
            large
            class="account-icons"
            :style="
              isSomeone
                ? 'border: 1px solid #ff6b00;'
                : 'border: 1px solid #6c757d;'
            "
            >mdi-account-plus-outline
          </v-icon>
          <br />

          <v-icon
            v-show="isSomeone"
            color="primary"
            large
            class="float-right account-icons-check"
          >
            mdi-check-circle
          </v-icon>
        </div>
      </v-card>
    </div>

    <v-col cols="12">
      <BasicDetails
        ref="basicDetails_comp"
        :beneficiaryDetails="account_user_Details"
        v-if="isSomeone"
      />
    </v-col>

    <v-col cols="12" md="12">
      <v-btn
        class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
        outlined
        @click="onClickBack"
        ><v-icon dark left>mdi-arrow-left</v-icon> {{ $t("backLabel") }}</v-btn
      >
      <v-btn
        class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
        outlined
        @click="onClickNext"
      >
        {{ $t("next") }}
        <v-icon dark right>mdi-arrow-right</v-icon>
      </v-btn>
    </v-col>
  </section>
</template>

<script>
import BasicDetails from "@/components/SendMoney/BasicDetails/index.vue";

export default {
  name: "AccountType",
  components: {
    BasicDetails,
  },
  data() {
    return {
      messages: 0,
      icon: 0,
      name: "",
      isSelf: true,
      hasError: false,
      isSomeone: false,
      hasErrorBusiness: false,
      account_user_Details: {},
    };
  },
  props: {
    accountDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  watch: {
    accountDetails(val) {
      if (val) {
        this.isSelf = val.isSelf;
        this.isSomeone = !val.isSelf;
        if (val.benficiaryDetails)
          this.account_user_Details = val.benficiaryDetails;
      }
    },
  },
  created() {
    // if (this.account_user_Details.type == "IND") {
    //   this.isSelf = true;
    //   this.hasError = true;
    //   this.isSomeone = false;
    //   this.hasErrorBusiness = false;
    // } else if (this.account_user_Details.type == "BUS") {
    //   this.isSelf = false;
    //   this.hasError = false;
    //   this.isSomeone = true;
    //   this.hasErrorBusiness = true;
    // }
  },
  methods: {
    selfSelected() {
      this.isSelf = true;
      this.isSomeone = false;
    },
    someoneSelected() {
      this.isSelf = false;
      this.isSomeone = true;
    },
    onClickNext() {
      if (this.isSelf) {
        this.$emit("onAccountNext", {
          accountDetails: {
            isSelf: this.isSelf,
          },
        });
      } else {
        let validate =
          this.$refs.basicDetails_comp.$refs.basicDetails.validate();
        if (this.$refs.basicDetails_comp.beneficiary.phone.length > 0) {
          if (validate || this.isSelf) {
            this.account_user_Details =
              this.$refs.basicDetails_comp.beneficiary;
            this.$emit("onAccountNext", {
              accountDetails: {
                benficiaryDetails: this.$refs.basicDetails_comp.beneficiary,
                isSelf: this.isSelf,
              },
            });
          }
        }
      }
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickPersonalAccount() {
      this.isSelf = true;
      this.hasError = true;
      this.isSomeone = false;
      this.hasErrorBusiness = false;
      const type = "IND";
      this.$emit("onAccounSelect", type);
    },
    onClickBusinessAccount() {
      this.isSelf = false;
      this.hasError = false;
      this.isSomeone = true;
      this.hasErrorBusiness = true;
      const type = "BUS";
      this.$emit("onAccounSelect", type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";

.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 10px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    // color: #fa6706;
    background: $white;
  }
}
.selected_class {
  &:hover {
    // border: 1px solid rgb(255, 0, 43) !important;
    // background: $white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    // width: %;
    background: #f8f9fa;
    border-radius: 7px;
  }
}
button.btn.px-5.py-3.btn-light.btn-lg.individual-select {
  border: 1px solid #ff6b00 !important;
  // color: #ff6b00;
  background: #fff;
}
button.btn.px-5.py-3.btn-light.btn-lg.business-select {
  border: 1px solid #ff6b00 !important;
  // color: #ff6b00;
  background: #fff;
}
.badge {
  border-radius: $badge-height;
}
.font {
  font-size: 120px;
}
.v-sheet.v-card {
  border-radius: 30px;
}
.border-primary {
  border: 1px solid $primary;
}
</style>
