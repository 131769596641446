<template>
  <v-card class="pa-5 main-card" rounded="lg" elevation="5">
    <v-row no-gutters>
      <v-col lg="6" sm="6">
        <label class="v-grap-text"> Exchange Rate </label>
      </v-col>
      <v-col lg="6" sm="6">
        <v-row no-gutters class="align-items-center">
          <span class="mr-3 mb-0 grap-flag-div">
            <country-flag :country="selected_flag" />
            <span class="v-grap-text ml-2 grap-flag-subdiv">{{
              target_currency
            }}</span>
          </span>
          <span
            class="v-grap-text v-grap-v-btn active-btn grap-btn"
            id="one week"
            >30D</span
          >
        </v-row>
      </v-col>
    </v-row>
    <apexchart
      type="area"
      height="243"
      ref="chart"
      :key="isKey"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
import { getspecifiedDate } from "@/utils/UsersList.js";
import commonService from "@/services/CommonService/commonService";
export default {
  props: ["target_currency", "selected_flag"],
  data() {
    return {
      no_of_days: 30,
      isKey: 1,
      series: [
        {
          name: "Rate",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          title: "Exchange Rates",
          id: "exchange-rate",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#ff6b00"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
          showNullDataPoints: true,
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return `${parseFloat(value).toFixed(2)} ${this.target_currency}`;
            },
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd-MM-yyyy",
          },
        },
      },
    };
  },
  watch: {
    selected_flag(val) {
      if (val) {
        this.getExchangeRate(val);
      }
    },
  },
  methods: {
    async getExchangeRate(country_code) {
      try {
        const responseData = await commonService.getGraphdata({
          country: country_code || "",
          no_of_days: "30",
          partner_code: null,
        });
        if (responseData.data.status_code === 200) {
          console.log("getExchangeRate22", responseData.data.data);
          this.$emit("selctedCntryExData", responseData.data.data);
          this.isKey = getspecifiedDate(new Date(), this.no_of_days).getTime();
          this.series[0].data =
            responseData.data.data.length > 0
              ? responseData.data.data.map((e) => {
                  return [new Date(e.created_date).getTime(), e.txn_rate];
                })
              : [];
          this.$store.state.data;
        }
      } catch (e) {
        return e;
      }
    },
  },
};
</script>

<style></style>
