<template>
  <v-container class="mb-0 pb-0">
    <v-alert
      prominent
      dense
      text
      type="error"
      class="pa-2"
      dismissible
      border="left"
      v-if="!isRegistartionDone"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ !isRegistartionDone ? $t("isUserRegistered") : null
          }}<v-btn
            color="primary"
            @click="
              $router.push(
                registeruserType === 'IND'
                  ? '/add-indivisual-user'
                  : registeruserType === 'BUS'
                  ? '/business-user-info'
                  : ''
              )
            "
            text
            ><v-icon>mdi-account-plus</v-icon> {{ $t("register_label") }}</v-btn
          >
        </v-col>
        <!-- <v-col class="shrink"> </v-col> -->
      </v-row>
    </v-alert>
    <v-alert
      prominent
      dense
      text
      type="error"
      class="pa-2"
      dismissible
      border="left"
      v-if="!iskycdone && isRegistartionDone"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ !iskycdone ? $t("kyccopleteError") : null
          }}<v-btn small color="primary" @click="$router.push('/kyc')" text
            ><v-icon small>mdi-shield-check</v-icon
            >{{ $t("click_here_label") }}</v-btn
          >
        </v-col>
        <!-- <v-col class="shrink" cols="2" md="3"> </v-col> -->
      </v-row>
    </v-alert>
    <v-alert
      v-if="alertMsg.status"
      dense
      :value="alertMsg.status"
      :key="alertMsg.key"
      id="alert"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <loading
      :active="Loadsendmoney"
      :is-full-page="false"
      :opacity="1"
      color="#ff6b00"
    />
    <v-card class="mt-0 mb-0 pa-4 pb-1 main-card" rounded="lg" elevation="5">
      <p
        v-if="is_partner"
        class="p-0 m-0 grey--text font-weight-bold text-caption"
      >
        <v-icon small class="mb-1">mdi-information-outline</v-icon>
        {{ $t("transaction_amount_exceed_limit") }}
      </p>

      <v-form ref="amountsection" lazy-validation>
        <v-row class="amountsection" no-gutters>
          <v-col cols="12" md="4" sm="12">
            <div class="form-inputs w-100 px-0">
              <label for="sendAmount" class="mb-0 custome-label-text">
                {{ $t("sendingAmount") }}
                <span style="color: #f00">*</span>
              </label>

              <v-text-field
                type="number"
                hide-spin-buttons
                outlined
                rounded
                :rules="is_partner ? EvoletAmtValidation : sendAmtValidation"
                id="sendAmount"
                v-model="amountSection.sendAmount"
                :hint="base_courency_hint"
                persistent-hint
                color="primary"
                required
                class="custome-text-field"
                @keyup.prevent="
                  getExchangefee('C', amountSection.sendAmount, '')
                "
              >
                <template v-slot:prepend-inner class="pt-0">
                  <country-flag country="my" class="mb-0" />
                  <span class="m-2 me-2">{{ $t("myr") }}</span>
                </template>

                <template v-slot:append>
                  <img
                    width="24"
                    height="24"
                    class="me-2"
                    src="@/assets/give-money-hand_down.svg"
                  />
                </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <div class="form-inputs w-100">
              <label for="PaymentMode" class="mb-0 custome-label-text">
                Payment Mode
                <span style="color: #f00">*</span>
              </label>

              <v-select
                attach
                id="PaymentMode"
                class="custome-text-field"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="filtered_country"
                item-text="text"
                item-value="value"
                item-disabled="isheader"
                :rules="paymentMode"
                :hint="payment_mode_hint"
                @change="getpaymode(amountSection.paymentMode)"
                persistent-hint
                outlined
                required
                v-model="amountSection.paymentMode"
              >
                <template v-slot:prepend-item class="pa-0 ma-0">
                  <v-list-item ripple @mousedown.prevent>
                    <v-text-field
                      v-model="searchCntry"
                      outlined
                      dense
                      placeholder="Search country"
                      hide-details
                      class="pa-0 ma-0"
                    >
                    </v-text-field>
                  </v-list-item>
                  <v-divider class="mt-0 pa-0 mb-0"></v-divider>
                </template>
                <template v-slot:item="data">
                  <template class="border">
                    <v-list-item
                      dense
                      ripple
                      class="light-gray"
                      @mousedown.prevent
                      v-if="data.item.isheader"
                    >
                      <v-list-item-content class="ma-0 pa-0">
                        <v-list-item-title class="d-flex ma-0 pa-0"
                          ><country-flag :country="data.item.avatar" /><span
                            class="pt-3 ps-3 font-weight-bold"
                            >{{ data.item.name }}</span
                          ></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="text-right border-list"
                      v-if="!data.item.isheader"
                    >
                      <v-list-item-content class="ma-0 pa-0">
                        <v-list-item-title>{{
                          data.item.text
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-divider></v-divider>
                </template>
                <template v-slot:prepend-inner>
                  <country-flag
                    :country="amountSection.paymentMode.avatar"
                    class="mb-0"
                  />
                  <span class="mx-2">{{
                    amountSection.paymentMode.avatar
                  }}</span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <div class="form-inputs w-100" rounded="lg">
              <label for="ReceivingAmount" class="mb-0 custome-label-text">
                Receive Amount
                <span style="color: #f00">*</span>
              </label>
              <v-text-field
                type="number"
                hide-spin-buttons
                outlined
                :rules="reciveAmtValidation"
                :hint="target_currency_hint"
                persistent-hint
                color="primary"
                class="custome-text-field"
                @keyup.prevent="
                  getExchangefeeReverse('P', '', amountSection.receiveAmount)
                "
                v-model="amountSection.receiveAmount"
              >
                <template v-slot:prepend-inner>
                  <v-select
                    :items="currency_list"
                    :label="target_currency"
                    v-model="selected_currency"
                    item-text="currency_code"
                    item-value="currency_code"
                    :menu-props="{ bottom: true, offsetY: true }"
                    solo
                    flat
                    class="custome-text custome-slect-prepend"
                    style="width: 70px; height: 33px; margin-bottom: 15px"
                  >
                  </v-select>
                </template>
                <template v-slot:append>
                  <img
                    width="24"
                    height="24"
                    class="me-2"
                    src="@/assets/receive-money-flat_hand.svg"
                  />
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-row class="apex-div">
      <v-col cols="12" md="6" sm="12" class="order-last order-md-first">
        <ExchangeRateGraph
          :target_currency="target_currency"
          :selected_flag="selected_flag"
          @selctedCntryExData="selctedCntryExData"
        />
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <loading
          :active="isTransSummary"
          :is-full-page="false"
          :opacity="0.7"
          color="#ff6b00"
        />
        <calcSummaryVue
          :target_currency="target_currency"
          :exchangeData="exchangeData"
          :base_currency="base_currency"
        />

        <v-col
          lg="12"
          sm="12"
          md="12"
          class="text-center p-0 v-send-money"
          v-if="!isEvolet"
        >
          <v-btn
            elevation="2"
            color="primary"
            @click="onClickNext"
            :disabled="isRegistartionDone && iskycdone ? false : true"
            block
            large
            class="text-center pa-6"
          >
            <v-icon class="mr-2">mdi-send</v-icon>

            {{ $t("fundTransfer") }}
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
    <v-dialog
      v-model="issendlimit"
      persistent
      :max-width="mobilebreakdown() ? '400' : '90%'"
    >
      <v-card class="pa-3">
        <v-card-text class="d-flex p-0">
          <div class="font">
            {{ $t("txn_not_allowed_label") }}
          </div>
        </v-card-text>
        <v-card-actions class="p-0">
          <v-spacer></v-spacer>
          <v-btn color="orange darken-1" text @click="issendlimit = false">
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import commonService from "@/services/CommonService/commonService";
import userservice from "@/services/UserService/userservice";
import { required } from "vuelidate/lib/validators";
import { validations } from "@/utils/validation";
import calcSummaryVue from "./calcSummary.vue";
import ExchangeRateGraph from "./ExchangeRateGraph.vue";
import { mobilebreakdown, webbreakdown } from "../../../utils/breakpoints";
import { mapState } from "vuex";
const {
  sendAmtValidation,
  reciveAmtValidation,
  paymentMode,
  EvoletAmtValidation,
} = validations;
export default {
  data() {
    return {
      mobilebreakdown,
      webbreakdown,
      issendlimit: false,
      isRegistartionDone: true,
      registeruserType: "",
      iskycdone: true,
      valid: true,
      alertMsg: {
        status: false,
        timeout: "",
        color: "",
        type: "",
        key: Date.now(),
      },
      searchCntry: "",
      policy_number: "",
      isTransSummary: false,
      Loadsendmoney: false,
      selected_service_charge: {},
      steperCal: 1,
      exchange_rate: [],
      sendAmtValidation,
      reciveAmtValidation,
      paymentMode,
      EvoletAmtValidation,
      paymentList: [],
      TotalpayoutDetails: [],
      currency_list: [],
      totalCurrency_list: [],
      payoutCountryDetails: [],
      country_list: [],
      select: null,
      target_currency: "",
      base_currency: "MYR",
      amountSection: {
        sendAmount: "100",
        paymentMode: {
          data: "",
          value: "",
        },
        receiveAmount: "",
        finalSendAmount: "",
      },
      convertData: {},
      isKey: 1,
      s_amount: "",

      country_obj: [],
      recieving_cntry: "",
      selected_country: {},
      selected_flag: "",
      payment_modeValue: "",
      Skipreciepient: null,
      BenePaymode: "",
      locationID: "",
      exchangeData: {},
      selectedCountryDetails: {},
      is_partner: false,
      cal_by: "",
      policy_rules: [(v) => !!v || "Policy Number is Required."],
      disale_paymode: false,
      is_self_beneficiary: null,
    };
  },
  components: {
    Loading,
    calcSummaryVue,
    ExchangeRateGraph,
  },
  validations: {
    amountSection: {
      paymentMode: { required },
    },
  },
  created() {
    this.is_self_beneficiary = JSON.parse(
      sessionStorage.getItem("is_self_beneficiary")
    );
  },
  async mounted() {
    this.getProfileData();
    // To check user is from Evolet or not
    let EvoletUser = sessionStorage.getItem("is_evolet_user");
    if (EvoletUser === "Evolet") {
      this.isEvolet = true;
    } else {
      this.isEvolet = false;
    }

    this.getCountryList();
  },
  props: {
    transactionDetails: Object,
  },

  watch: {
    //to get the flag for the selected country
    selected_country(val) {
      let selctedcurrency = val.text ? val.text : val;
      console.log("selected_country", selctedcurrency);
      console.log("country_obj", this.country_obj);
      this.country_list.map((country) => {
        if (country.text === selctedcurrency) {
          console.log(country);
          this.selectedCountryDetails = {
            text: country.text,
            name: country.name,
            country_code: country.avatar,
          };
        }
      });
      this.selected_flag = this.selectedCountryDetails.country_code;
      this.recieving_cntry = this.selectedCountryDetails.country_code;
      this.country_obj.map(async (country) => {
        if (country.receiverCurrency === selctedcurrency) {
          this.convertData.exchange_rate = country.exchangeRate;
          this.target_currency = country.receiverCurrency;
        }
      });
    },

    transactionDetails(val) {
      if (val.target_currency) {
        if (val.amountSection) this.amountSection = { ...val.amountSection };
        if (val.target_currency) this.target_currency = val.target_currency;
        if (val.base_currency) this.base_currency = val.base_currency;
        if (val.convertData) this.convertData = val.convertData;
        if (val.selected_service_charge)
          this.selected_service_charge = val.selected_service_charge;
        if (!val.amountSection?.receiveAmount) this.getConvertRate();
      }
    },

    "alertMsg.status"(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alertMsg.status = false;
        }, 3000);
      }
    },
  },
  computed: {
    ...mapState(["userDetails"]),
    filtered_country() {
      if (this.searchCntry) {
        var iso3 = "";
        return this.payoutCountryDetails.map((ele) => {
          console.log(ele);
          if (ele.isheader) {
            console.log("test", ele.name);
            const mainText = ele.name.toUpperCase();
            const searchText = this.searchCntry.toUpperCase();
            if (mainText.includes(searchText)) {
              iso3 = ele.avatar;
              return ele;
            }
          } else if (iso3 == ele.value.avatar) {
            return ele;
          }
        });
      } else {
        return this.payoutCountryDetails;
      }
    },
    payment_mode_hint() {
      return this.exchangeData && this.exchangeData.serviceCharge
        ? `${this.$t("fee")} = ${this.exchangeData.serviceCharge} MYR`
        : "";
    },
    base_courency_hint() {
      return this.convertData.exchangeRate
        ? `1 ${this.base_currency} = ${this.convertData.exchangeRate} ${this.target_currency}`
        : "";
    },
    target_currency_hint() {
      return this.amountSection.finalSendAmount
        ? `${this.$t("converting")} = ${this.amountSection.finalSendAmount} MYR`
        : "";
    },
  },
  methods: {
    selctedCntryExData(val) {
      this.exchange_rate = val;
    },
    //using to get the transaction summary
    async getExchangefee(ExchangeType, Amount, recievieAmount) {
      console.log("log karo", this.selected_country);
      this.alertMsg.status = false;
      const params = {
        transfer_amount: Amount ? Amount : recievieAmount,
        payout_currency: this.selected_currency.currency_code,
        location_id: this.locationID,
        payment_mode: this.amountSection.paymentMode.code,
        payout_country: this.amountSection.paymentMode.avatar,
        calculation_by: ExchangeType,
        send_country: "MYS",
        send_currency: "MYR",
      };
      // const token = sessionStorage.getItem("access_token");
      if (!this.Loadsendmoney) {
        this.isTransSummary = true;
      }
      try {
        const responseData = await commonService.getExchangefee(params);
        if (responseData.data.status_code === 200) {
          this.cal_by = "C";
          this.convertData = responseData.data.data[0];
          console.log("convertData", this.convertData.payoutAmount);
          this.amountSection.finalSendAmount = parseFloat(
            this.convertData.transferAmount
          ).toFixed(2);
          this.amountSection.receiveAmount = parseFloat(
            this.convertData.payoutAmount
          ).toFixed(2);

          this.isTransSummary = false;
          this.exchangeData = responseData.data.data[0];
        } else {
          this.cal_by = "";
          console.log("Evolet Send", this.amountSection.sendAmount);
          this.isTransSummary = false;
          this.exchangeData = [];
          this.amountSection.sendAmount = "";
          if (!this.is_partner) {
            this.alertMsg = {
              status: true,
              text: `${responseData.data.status_code}: ${responseData.data.message}`,
              color: "warning",
              type: "warning",
              timeout: 2,
              key: Date.now(),
            };
          }
        }
      } catch (e) {
        console.log("Evolet Send", this.amountSection.sendAmount);
        this.cal_by = "";
        if (!this.is_partner) {
          this.alertMsg = {
            status: true,
            text: "Something went wrong.Please Contact QRate Support Team",
            color: "warning",
            type: "warning",
            timeout: 2,
            key: Date.now(),
          };
        }
        this.isTransSummary = false;
        this.amountSection.sendAmount = "";

        console.log(e);
        this.exchangeData = [];
        this.amountSection.receiveAmount = 0;
      }
    },
    async getExchangefeeReverse(ExchangeType, Amount, recievieAmount) {
      this.alertMsg.status = false;
      if (!this.Loadsendmoney) {
        this.isTransSummary = true;
      }
      const params = {
        transfer_amount: Amount ? Amount : recievieAmount,
        payout_currency: this.selected_currency.currency_code,
        location_id: this.locationID,
        payment_mode: this.amountSection.paymentMode.code,
        payout_country: this.amountSection.paymentMode.avatar,
        calculation_by: ExchangeType,
        send_country: "MYS",
        send_currency: "MYR",
      };
      try {
        const responseData = await commonService.getExchangefee(params);
        if (responseData.data.status_code === 200) {
          this.cal_by = "P";
          this.convertData = responseData.data.data[0];
          console.log("convertData", this.convertData.payoutAmount);
          this.amountSection.finalSendAmount = parseFloat(
            this.convertData.transferAmount
          ).toFixed(2);
          this.amountSection.sendAmount = parseFloat(
            this.convertData.transferAmount
          ).toFixed(2);
          this.isTransSummary = false;
          this.exchangeData = responseData.data.data[0];
        } else {
          this.cal_by = "";
          if (!this.is_partner) {
            this.alertMsg = {
              status: true,
              text: `${responseData.data.status_code}: ${responseData.data.message}`,
              color: "warning",
              type: "warning",
              timeout: 2,
              key: Date.now(),
            };
          }
          this.isTransSummary = false;
          this.exchangeData = [];
          console.log("Evolet Send", this.amountSection.sendAmount);
          this.amountSection.sendAmount = "";
        }
      } catch (e) {
        this.cal_by = "";
        this.isTransSummary = false;
        console.log("Evolet Send", this.amountSection.sendAmount);
        this.amountSection.sendAmount = "";
        if (!this.is_partner) {
          this.alertMsg = {
            status: true,
            text: "Something went wrong.Please Contact QRate Support Team",
            color: "warning",
            type: "warning",
            timeout: 2,
            key: Date.now(),
          };
        }

        console.log(e);
        this.exchangeData = [];
      }
    },
    getpaymode(val) {
      console.log("paymode val", val);
      // clearing Searching string to filtering the data
      this.searchCntry = "";
      if (val.code) {
        this.currency_list = this.totalCurrency_list.flatMap((ele) => {
          if (
            ele.country_code === val.avatar &&
            ele.payment_mode === val.code
          ) {
            this.payment_modeValue = ele.payment_mode_value;
            return ele.currncy_list;
          } else return [];
        });
        this.selected_currency = this.currency_list[0];
        this.selected_flag = this.amountSection.paymentMode.avatar;
        this.target_currency = this.currency_list[0].currency_code;
        this.getExchangefee("C", this.amountSection.sendAmount, "");
        //to map the payment mode while creatiing bene
        this.$store.state.selctedPayMode = val.code;
        if (val != this.BenePaymode) {
          //if payment mode changes recipient should select
          this.$store.state.data = {};
        }
      }
    },
    //to get list of countries
    async getCountryList() {
      this.Loadsendmoney = true;
      try {
        const responseData = await commonService.getBeneficiaryCountryList();
        if (responseData.data.status_code) {
          this.Loadsendmoney = false;
          responseData.data.data.map((i) => {
            this.payoutCountryDetails.push({
              avatar: i.iso_alpha3,
              name: i.country_name,
              isheader: true,
            });
            i.allowed_payment_modes.map((ele) => {
              this.payoutCountryDetails.push({
                text: ele.value,
                value: {
                  avatar: i.iso_alpha3,
                  name: i.country_name,
                  code: ele.value_code,
                },
                isheader: false,
              });

              this.totalCurrency_list.push({
                country_code: i.iso_alpha3,
                payment_mode: ele.value_code,
                payment_mode_value: ele.value,
                currncy_list: ele.allowed_currency,
              });
            });
          });
          // Calling All api's according to country APi List
          if (!this.$store.state.data.receiver_id) {
            this.amountSection.paymentMode = this.payoutCountryDetails[1].value;
            this.currency_list = this.totalCurrency_list.flatMap((ele) => {
              if (
                ele.country_code ===
                  this.payoutCountryDetails[1].value.avatar &&
                ele.payment_mode === this.payoutCountryDetails[1].value.code
              ) {
                this.payment_modeValue = ele.payment_mode_value;
                return ele.currncy_list;
              } else return [];
            });
            this.selected_currency = this.currency_list[0];
            this.selected_flag = this.amountSection.paymentMode.avatar;
            this.target_currency = this.currency_list[0].currency_code;

            this.getExchangefee("C", this.amountSection.sendAmount, "");
          } else {
            let countryData = this.$store.state.data;
            console.log("countryData", countryData);
            this.selected_country = {
              text: countryData.currency_code,
              avatar: countryData.country_code.iso_alpha3,
              name: countryData.country_code.name,
              country_code: countryData.country_code.iso_alpha3,
            };
            console.log("this.selected_country", this.selected_country);
          }
          //if bene is coming from bene summary
          console.log("this.$store", this.$store.state.data.receiver_id);
        } else {
          this.Loadsendmoney = false;
        }
      } catch (err) {
        this.Loadsendmoney = false;
      }
    },

    async getProfileData() {
      this.registeruserType = sessionStorage.getItem("user_type");
      this.Loadsendmoney = true;
      try {
        const responseData = await userservice.getProfileData();
        if (responseData.data.status_code == 200) {
          console.log("responseData33Profile", responseData);
          this.Loadsendmoney = false;
          this.isRegistartionDone = responseData.data[0].personal_info;
          this.iskycdone = responseData.data[0].kyc;
        } else {
          this.isRegistartionDone = false;
          this.iskycdone = false;
          this.Loadsendmoney = false;
        }
      } catch (err) {
        console.log(err);
        this.Loadsendmoney = false;
      }
    },
    async onClickNext() {
      this.Loadsendmoney = true;
      // let responseData = await this.$store.dispatch("Transactional/getProfileData");
      const responseData = await userservice.getProfileData();

      console.log("responseData", responseData.data[0]);
      let kyc = sessionStorage.getItem("is_kyc");
      console.log(
        "complete_registration",
        this.$store.state.complete_registration
      );
      console.log("kyc 33", kyc);
      if (!responseData.data.data[0].personal_info) {
        this.Loadsendmoney = false;

        this.alertMsg = {
          status: true,
          text: "Please complete the registration process to proceed",
          color: "warning",
          type: "warning",
          timeout: 2,
          key: Date.now(),
        };
      } else if (!responseData.data.data[0].kyc) {
        this.Loadsendmoney = false;
        this.alertMsg = {
          status: true,
          text: "Please complete the KYC to proceed",
          color: "warning",
          type: "warning",
          timeout: 2,
          key: Date.now(),
        };
      } else {
        this.Loadsendmoney = false;
        const valid = this.$refs.amountsection.validate();
        let tempData = this.paymentList.find(
          (e) => e.value === this.amountSection.paymentMode.value
        );
        if (
          valid &&
          this.exchangeData &&
          Math.floor(this.exchangeData.payoutAmount) ===
            Math.floor(this.amountSection.receiveAmount)
        ) {
          console.log(
            "this.convertData",
            Math.floor(this.amountSection.receiveAmount)
          );
          this.$emit("onNextClick", {
            transactionDetails: {
              amountSection: this.amountSection,
              payment_modeValue: this.payment_modeValue,
              selected_service_charge: tempData,
              exchange_rate: this.exchange_rate,
              target_currency: this.target_currency,
              selected_country: this.selected_country,
              country_name: this.recieving_cntry,
              base_currency: this.base_currency,
              convertData: this.convertData,
              exchangeData: this.exchangeData,
              cal_by: this.cal_by,
              policy_number: this.policy_number,
            },
          });
          console.log("this.selected_country", this.selectedCountryDetails);
          console.log("paymentMode:", this.amountSection.paymentMode);
          this.$store.state.selctedPayMode = this.amountSection.paymentMode;
          this.$store.state.paymodeCountry = {
            paymode: this.amountSection.paymentMode.code,
            country: {
              country_code: this.amountSection.paymentMode.avatar,
            },
            policy_number: this.policy_number ? this.policy_number : null,
          };
        } else {
          this.alertMsg = {
            status: true,
            text: "The transaction details are not support.Kindly check and try again.",
            color: "warning",
            type: "warning",
            timeout: 2,
            key: Date.now(),
          };
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/global.scss";

.form-inputs {
  display: inline-flex !important;
  flex-direction: column !important;
  padding: 1rem !important;
  margin: 2px 0px 2px 0px !important;
}
.amountsection {
  display: flex;
  justify-content: space-around;
}
.fixed-width {
  width: 24px !important;
  height: 24px !important;
}
.normal-flag {
  margin: 0px;
}
.v-text-field {
  padding-top: 0px;
  margin: 0px 6px;
}
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-top: 0px !important;
}
.v-input__prepend-inner {
  display: flex;
  justify-content: center;
  align-items: center !important;
  min-height: 56px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.42);
  margin: 0px !important;
  padding-top: 0px !important;

  margin-right: 5px !important;
  color: gray;
}
.v-input__prepend-inner .v-input {
  font-size: 24px !important;
  padding-left: 2px;
}

.v-input__slot {
  padding-left: 5px !important;
}
.v-grap-v-btn {
  box-shadow: none !important;
  background-color: transparent !important;
}
.v-grap-text {
  font-size: 23px !important;
  color: $lightgrey !important;
}
.custome-label-text {
  font-size: 0.8rem;
  color: gray;
  font-weight: bold !important;
}

.v-custome-icon .v-icon {
  color: gray !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.v-custome-icon {
  background-color: #ffff;
  display: flex;
  align-items: center;
  margin: auto;
  margin-left: auto;
  margin-right: -24px;
}
// .fixed-height-div {
//   min-height: fit-content !important;
// }
.custome-text-field .v-text-field__slot {
  font-size: 20px !important;
}
.custome-slect-prepend .v-input__slot .v-select__slot .v-select__selections {
  font-size: 16px !important;
  color: gray !important;
  padding-right: 0px !important;
}

.custome-text-field .v-input__slot {
  padding-right: 0px !important;
}
.custome-text-field .v-messages__wrapper {
  font-weight: bold;
}

.custome-slect-prepend .v-input__slot .v-select__slot label {
  color: gray !important;
}
.card-heading {
  display: flex;
  justify-content: center;
  font-size: 24px;
  background-color: #e8e7e7;
  margin-bottom: 12px;
  padding: 8px;
  font-weight: 700;
  color: gray;
  max-height: 43px;
}
.light-gray {
  // background-color: rgb(242, 238, 238);
  font-weight: 700;
  color: rgba(50, 48, 48, 0.6);
  border-bottom: 2px solid rgb(234, 229, 229);
  background: linear-gradient(270deg, #f0efef 70%, $primary 200%);
}

.v-list-item--disabled {
  padding: 0px !important;
}
.apex-div {
  margin-bottom: 10px !important;
}
.v-application--is-ltr .v-text-field .v-input__append-inner,
.v-application--is-rtl .v-text-field .v-input__prepend-inner {
  padding-left: 0px !important;
}
.font {
  font-size: 15px;
  font-weight: 800;
}
</style>
