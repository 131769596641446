<template>
  <v-container>
    <v-row>
      <v-dialog v-model="showDialog" scrollable>
        <v-col cols="12" class="p-0">
          <Beneficiary
            @closeDialog="closeDialog"
            :showDialog="showDialog"
            :policy_number="recievedPolicy_number"
          /> </v-col
      ></v-dialog>
      <v-dialog v-model="showBusDialog" scrollable>
        <v-col cols="12" class="p-0">
          <BusBeneficiary
            @closeBusDialog="closeBusDialog"
            :showBusDialog="showBusDialog"
          /> </v-col
      ></v-dialog>

      <v-col cols="12 ">
        <v-row no-gutters>
          <v-col lg="6" sm="12" v-if="!is_self_beneficiary">
            <v-btn
              v-if="is_partner"
              class="me-2 mt-5 px-5 v-custome-btn pa-5"
              color="primary"
              @click="onAddBen"
              ><v-icon dark left>mdi-account-plus </v-icon>
              {{ $t("add_bene_label") }}</v-btn
            >
            <v-menu :offset-x="true" open-on-hover v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  color="primary"
                  class="me-2 mt-5 v-custome-btn pa-5"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark left>mdi-account-plus </v-icon>
                  {{ $t("add_bene_label") }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="onAddBen">
                  <v-list-item-title
                    ><v-icon class="mb-1 me-2"> mdi-account-tie</v-icon>
                    {{ $t("personal") }}</v-list-item-title
                  >
                </v-list-item>
                <v-list-item link @click="onAddBusBen">
                  <v-list-item-title
                    ><v-icon class="mb-1 me-2">mdi-briefcase-account</v-icon
                    >{{ $t("business") }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col lg="6" sm="12" class="">
            <v-text-field
              v-model="search"
              append-icon="mdi-filter"
              :placeholder="$t('search_to_filter')"
              class="mb-2 mt-5"
              color="primary"
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="recipientHeaders"
          :items="receipientList"
          :single-select="true"
          :search="search"
          :items-per-page="3"
          :scrollable="true"
          v-model="receipientDetails"
          item-key="receiver_id"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          class="elevation-1 recipent row-height-50"
          ref="reciepiet"
          @click:row="handleRowSelect"
          :hide-default-header="true"
          dense
          item-class="style-1"
          height="200px"
        >
          <template v-slot:item.first_name="{ item }">
            <div class="d-flex">
              <div :class="webbreakdown() ? 'd-flex width-100' : 'd-flex'">
                <div class="d-flex center">
                  <span class="text-center">
                    <flag
                      :iso="getIndCountry(item.country_code)"
                      :class="webbreakdown() ? 'flag-style' : 'flag-size'"
                    />
                  </span>
                </div>
                <div>
                  <p
                    :class="
                      webbreakdown()
                        ? 'm-0 mt-2 ms-3 font-name p-0'
                        : 'm-0 mt-2 ms-3  p-0'
                    "
                  >
                    {{ `${item.full_name}` }}
                  </p>
                  <div class="d-flex" v-if="webbreakdown()">
                    <div class="icon-size d-flex center ps-2 pe-2">
                      <v-icon>{{
                        item.payment_code === "B" ? "mdi-bank" : "mdi-cash"
                      }}</v-icon>
                    </div>
                    <div class="text-col" v-if="item.payment_code === 'B'">
                      <span class="">
                        {{ item.location_name }}
                      </span>
                      <p class="p-0 m-0">{{ item.bank_account_no }}</p>
                    </div>
                    <div class="text-col" v-if="item.payment_code === 'C'">
                      <span class="">
                        {{ "Cash Pickup" }}
                      </span>
                      <p class="p-0 m-0">{{ item.location_name }}</p>
                    </div>
                    <div class="text-col" v-if="item.payment_code === 'W'">
                      <span class="">
                        {{ "eWallet" }}
                      </span>
                      <p class="p-0 m-0">{{ item.location_name }}</p>
                    </div>
                    <div
                      class="text-col"
                      v-if="item.payment_code.payment_mode_code === 'A'"
                    >
                      <span class="">
                        {{ "Bpjs" }}
                      </span>
                      <p class="p-0 m-0">{{ item.policy_number }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div cols="1" sm="1" class="d-flex center" v-if="webbreakdown()">
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </template>

          <template v-slot:item.country_code="{ item }">
            <p class="d-flex align-items-center width" v-if="mobilebreakdown()">
              <country-flag :country="item.country_code" size="big" />
              <span class="mt-2 mx-2">{{ item.country_code }}</span>
            </p>
          </template>

          <template v-slot:item.rec_type="{ item }">
            <span class="" v-if="mobilebreakdown()">{{
              item.rec_type === "IND" ? "Individual" : "Bussines"
            }}</span>
          </template>

          <template v-slot:item.action="{ item }">
            <v-radio-group v-model="isSelected" v-if="mobilebreakdown()">
              <v-radio color="primary" :key="item.receiver_id" :value="item.receiver_id">
              </v-radio>
            </v-radio-group>
          </template>

          <template v-slot:item.location_name="{ item }">
            <span class="" v-if="mobilebreakdown()">
              <v-icon>{{ item.payment_code === "B" ? "mdi-bank" : "mdi-cash" }}</v-icon>

              <span v-if="item.payment_code === 'B'"> {{ item.location_name }}</span>
              <span v-if="item.payment_code === 'C'"> {{ "Cash Pay" }}</span>
              <div class="text-col" v-if="item.payment_code === 'W'">
                <span class="">
                  {{ "eWallet" }}
                </span>
                <p class="p-0 m-0">{{ item.location_name }}</p>
              </div>
              <div class="text-col" v-if="item.payment_code.payment_mode_code === 'A'">
                <span class="">
                  {{ "Bpjs" }}
                </span>
                <p class="p-0 m-0">{{ item.policy_number }}</p>
              </div>
            </span>
          </template>
          <template v-slot:item.bank_account_no="{ item }">
            <span v-if="mobilebreakdown()">{{ item.bank_account_no }}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn
          class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          @click="onClickBack"
          ><v-icon dark left>mdi-arrow-left</v-icon> {{ $t("backLabel") }}</v-btn
        >
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          :disabled="!isSelected"
          @click="onClickNext"
          >{{ $t("next") }} <v-icon dark right>mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Axios from "axios";
// import { EventBus } from "../../../plugins/events.js";
import Beneficiary from "@/views/beneficiary/CreateBeneSendMoney.vue";
import BusBeneficiary from "@/views/beneficiary/createBusBeneficiary.vue";
import remitteService from "@/services/RemitteService/remitteService";

export default {
  name: "Recipient",
  data() {
    return {
      isSelected: null,
      isLoading: false,
      search: "",
      isAddBen: false,
      recievedPolicy_number: "",
      receipientDetails: [],
      receipientList: [],
      recipientHeaders: [
        { text: "", value: "action" },
        { text: "Name ", value: "first_name" },
        { text: "Customer Type", value: "rec_type" },
        { text: "Bank Name", value: "location_name" },
        { text: "Account No", value: "bank_account_no" },
      ],
      country_obj: [],
      showDialog: false,
      is_self_beneficiary: null,
      showBusDialog: false,
      is_partner: false,
    };
  },
  components: { Beneficiary, BusBeneficiary },
  computed: {
    receipientListItems() {
      return this.$store.state.receipientListItems;
    },
  },
  created() {
    this.getCountryList();
    this.is_self_beneficiary = JSON.parse(sessionStorage.getItem("is_self_beneficiary"));
  },
  mounted() {
    this.is_partner = this.$getLocalStorage("is_partner");
    console.log("created bene details: ", this.$store.state.CreatedBeneDetails);
    let createdBeneDetails = this.$store.state.CreatedBeneDetails;
    if (createdBeneDetails.country_code && createdBeneDetails.payment_code) {
      console.log("country name: ", createdBeneDetails.country_code);
      console.log("payment Code: ", createdBeneDetails.payment_code);
      this.getBenficaryDetails(
        createdBeneDetails.country_code,
        createdBeneDetails.payment_code
      );
    } else {
      this.getBenficaryDetails(
        this.serviceCharge.country_name,
        this.serviceCharge.selected_service_charge.data
      );
    }
  },
  watch: {
    //to watch store value to call Beneficiary list api
    "$store.state.paymodeCountry": function () {
      console.log("$store.state.paymodeCountry", this.$store.state.paymodeCountry);
      if (this.$store.state.paymodeCountry.country.country_code === "IND") {
        this.getBenficaryDetails(
          this.$store.state.paymodeCountry.country.country_code,
          this.$store.state.paymodeCountry.paymode
        );
      } else if (this.$store.state.paymodeCountry.policy_number) {
        this.recievedPolicy_number = this.$store.state.paymodeCountry.policy_number;
        this.getpolicyBene(this.$store.state.paymodeCountry.policy_number);
      } else {
        this.getBenficaryDetails(
          this.$store.state.paymodeCountry.country.country_code,
          this.$store.state.paymodeCountry.paymode
        );
      }
    },
    "$store.state.CreatedBeneDetails": function () {
      console.log("value 2 bene details store:", this.$store.state.CreatedBeneDetails);

      let createdBeneDetails = this.$store.state.CreatedBeneDetails;
      if (createdBeneDetails.payment_code === "A" && createdBeneDetails.policy_number) {
        this.getpolicyBene(createdBeneDetails.policy_number);
      } else if (createdBeneDetails.country_code && createdBeneDetails.payment_code) {
        console.log("country name: ", createdBeneDetails.country_code);
        console.log("payment Code: ", createdBeneDetails.payment_code);
        this.getBenficaryDetails(
          createdBeneDetails.country_code,
          createdBeneDetails.payment_code
        );
      }
      this.isSelected = createdBeneDetails.receiver_id;
    },

    serviceCharge: {
      immediate: true,
      handler(val) {
        console.log("servicecharge from watcher: ", val);
        if (Object.values(val).includes(undefined)) {
          return;
        } else {
          this.getBenficaryDetails(val.country_name, val.selected_service_charge?.data);
        }
      },
    },
  },
  props: {
    beneficiaryData: {
      type: Object,
      default: () => {},
    },
    target_currency: {
      type: String,
      default: () => "",
    },
    serviceCharge: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closeDialog(val) {
      console.log(val);
      this.showDialog = val;
    },
    closeBusDialog(val) {
      console.log(val);
      this.showBusDialog = val;
    },
    getIndCountry(val) {
      let valuwe = "";
      this.country_obj.map((i) => {
        if (val === i.iso_alpha3) {
          valuwe = i.iso_alpha2;
        }
      });
      return valuwe;
    },
    async getCountryList() {
      let responseData = await this.$store.dispatch(
        "Authentication/getBeneficiaryCountryList"
      );
      responseData.data.data.map((i) => {
        this.country_obj.push(i);
      });
      console.log("country_obj", this.country_obj);
    },
    mobilebreakdown() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    webbreakdown() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    handleRowSelect(item, row) {
      console.log("row", row);
      row.select(true);
      this.getRepDetails(item);
      this.receipientDetails = [{ ...item }];
      this.isSelected = item.receiver_id;
    },
    async getRepDetails(item) {
      try {
        const responseData = await this.$store.dispatch(
          "Remittee/getRemitteDetailByID",
          item.receiver_id
        );
        console.log("res start", responseData);
        if (responseData.data.status_code === 200) {
          this.loader = false;
          this.receipientDetails = [responseData.data.data[0]];
        } else {
          this.loader = false;
          this.receipientDetails = [{ ...item }];
        }
      } catch (err) {
        this.receipientDetails = [{ ...item }];
        this.loader = false;
        console.log(err);
      }
    },
    onAddBen() {
      this.showDialog = true;
      this.$store.state.showDialog = true;
      // this.$router.push("/beneficiary/true");
    },
    onAddBusBen() {
      this.showBusDialog = true;
      this.$store.state.showDialog = true;
      // this.$router.push("/beneficiary/true");
    },
    async getpolicyBene(policy_number) {
      console.log("getBenficaryDetails called...", this.receipientList);
      this.isLoading = true;
      try {
        const responseData = await remitteService.getBenePolicyNo(policy_number);
        console.log("getting policy**", responseData.data.data);
        if (responseData.data.status_code === 200) {
          this.isLoading = false;
          this.receipientList =
            Array.isArray(responseData.data.data) && responseData.data.data.length > 0
              ? responseData.data.data
              : [];
          this.receipientList = responseData.data.data.reverse();
          if (responseData.data.data.length > 0) {
            this.receipientDetails = [this.receipientList[0]];
            this.isSelected = responseData.data.data[0].receiver_id;
          }
          this.$store.commit("SET_RECEIPIENT_LIST", responseData.data.data);
        } else {
          this.isLoading = false;
          this.receipientList = [];
        }
        // this.isLoading = true;

        // const token = sessionStorage.getItem("access_token");
        // await Axios({
        //   method: "GET",
        //   url: `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene/get-receiver-by-policy?policy_number=${policy_number}`,
        //   headers: {
        //     accept: "application/json",
        //     Authorization: `Bearer ${token}`,
        //   },
        // })
        //   .then((responseData) => {
        //     console.log("getting response**", responseData.data.data);
        //     this.isLoading = false;
        //     if (responseData.status === 200) {
        //   this.receipientList =
        //     Array.isArray(responseData.data.data) && responseData.data.data.length > 0
        //       ? responseData.data.data
        //       : [];
        //   this.receipientList = responseData.data.data.reverse();
        //   if (responseData.data.data.length > 0) {
        //     this.receipientDetails = [this.receipientList[0]];
        //     this.isSelected = responseData.data.data[0].receiver_id;
        //   }
        //   this.$store.commit("SET_RECEIPIENT_LIST", responseData.data.data);
        // }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     this.isLoading = false;
        //     this.receipientList = [];
        //   });
      } catch (e) {
        console.log("receipint error: ", e);
        this.isLoading = false;
        return e;
      }
    },
    async getBenficaryDetails(country_name, payment_mode) {
      console.log("getBenficaryDetails called...", this.receipientList);
      if (!country_name) return;
      console.log("has country_name**", country_name);
      try {
        this.isLoading = true;
        const responseData = await remitteService.getBeneDetails(
          country_name,
          payment_mode
        );
        console.log("getting response**", responseData.data.data);
        if (responseData.data.status_code === 200) {
          this.isLoading = false;
          this.receipientList =
            Array.isArray(responseData.data.data) && responseData.data.data.length > 0
              ? responseData.data.data
              : [];
          this.receipientList = responseData.data.data.reverse();

          this.$store.commit("SET_RECEIPIENT_LIST", responseData.data.data);
        } else {
          this.isLoading = false;
          this.receipientList = [];
        }
        // const token = sessionStorage.getItem("access_token");
        // await Axios({
        //   method: "GET",
        //   url: `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene/remitter/get-remittee-list/by-country?country=${country_name}&payment_mode=${payment_mode}`,
        //   headers: {
        //     accept: "application/json",
        //     Authorization: `Bearer ${token}`,
        //   },
        // })
        //   .then((responseData) => {
        //     // console.log("getting response**", responseData.data.data);
        //     this.isLoading = false;
        // if (responseData.status === 200) {
        //   this.receipientList =
        //     Array.isArray(responseData.data.data) &&
        //     responseData.data.data.length > 0
        //       ? responseData.data.data
        //       : [];
        //   this.receipientList = responseData.data.data.reverse();

        //   this.$store.commit("SET_RECEIPIENT_LIST", responseData.data.data);
        // }
        //   })
        //   .catch((err) => {
        // console.log(err);
        // this.isLoading = false;
        // this.receipientList = [];
        //   });
      } catch (e) {
        console.log("receipint error: ", e);
        this.isLoading = false;
        return e;
      }
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      this.$emit("onNextClick", {
        reciepient: { ...this.receipientDetails[0] },
      });
    },
  },
};
</script>

<style lang="scss">
.v-custome-btn {
  border-radius: 10px !important;
}
.font {
  font-size: 9px;
}
.font-name {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  height: auto !important;
  min-height: 2px !important;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
  text-align: left !important;
}
.icon-size {
  font-size: 23px;
  color: rgb(130, 127, 127);
}

.text-col {
  color: rgb(130, 127, 127);
  font-size: 12px;
}
.center {
  justify-content: center;
  align-items: center;
}
.flag-style {
  font-size: 46px;
  border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.flag-size {
  font-size: 33px;
  border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.width-100 {
  width: 78vw;
}
.v-dialog {
  margin: 0px;
}
</style>
