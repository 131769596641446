<template>
  <v-container>
    <!-- <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert> -->
    <v-row>
      <loading :active="loader" :opacity="0.7" color="#ff6b00" />

      <v-expansion-panels accordion v-model="panelOpened">
        <v-expansion-panel
          v-if="transactionDetails.transactionDetails"
          key="transaction"
          color="primary"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <strong> {{ $t("transactionDetails") }}</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="amountsection main-row" no-gutters>
              <v-col lg="12" md="12" sm="12" class="box-div mb-2">
                <div class="mt-1 p-0 outlined">
                  <div class="d-flex">
                    <v-sheet
                      class="v-sheet--offset ms-2 pt-1 text-center"
                      width="180px"
                      elevation="0"
                    >
                      {{ $t("summary") }}
                    </v-sheet>
                  </div>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="text-left">
                            {{ $t("CollectedAmount") }}
                          </td>
                          <td class="text-left">
                            {{
                              Number(
                                transactionDetails.transactionDetails
                                  .exchangeData.collectAmount
                              )
                                .toFixed(2)
                                .toLocaleString()
                            }}{{ " "
                            }}{{
                              transactionDetails.transactionDetails
                                .base_currency
                            }}

                            <img
                              class="ml-2 extra_space"
                              width="24"
                              height="24"
                              src="@/assets/give-money-hand_down.svg"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("fee") }}
                          </td>
                          <td class="text-left">
                            {{
                              Number(
                                +Number(
                                  transactionDetails.transactionDetails
                                    .exchangeData.serviceCharge || 0
                                ).toFixed(2)
                              ).toLocaleString()
                            }}
                            {{
                              transactionDetails.transactionDetails
                                .base_currency
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("TransferAmount") }}
                          </td>
                          <td class="text-left">
                            {{
                              Number(
                                +Number(
                                  transactionDetails.transactionDetails
                                    .exchangeData.transferAmount || 0
                                ).toFixed(2)
                              ).toLocaleString()
                            }}
                            {{
                              transactionDetails.transactionDetails
                                .base_currency
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("convertingAmount") }}(1{{
                              transactionDetails.transactionDetails
                                .base_currency
                            }})
                          </td>
                          <td class="text-left">
                            {{
                              Number(
                                +Number(
                                  transactionDetails.transactionDetails
                                    .exchangeData.exchangeRate || 0
                                ).toFixed(2)
                              ).toLocaleString()
                            }}{{
                              transactionDetails.transactionDetails
                                .target_currency
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("payoutMode") }}
                          </td>
                          <td class="text-left">
                            <!-- {{
                              transactionDetails.reciepient &&
                              transactionDetails.reciepient.payment_code
                                .payment_mode
                                ? transactionDetails.reciepient.payment_code
                                    .payment_mode
                                : reciepientDetails.payment_code.payment_mode
                            }} -->
                            {{
                              transactionDetails.transactionDetails &&
                              transactionDetails.transactionDetails
                                .payment_modeValue
                                ? transactionDetails.transactionDetails
                                    .payment_modeValue
                                : ""
                            }}
                            <!-- {{
                              (transactionDetails.transactionDetails
                                .selected_service_charge &&
                                transactionDetails.transactionDetails
                                  .selected_service_charge.value) ||
                              "N/A"
                            }} -->
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("receiveAmount") }}
                          </td>
                          <td class="text-left">
                            {{
                              Number(
                                transactionDetails.transactionDetails
                                  .amountSection.receiveAmount || 0
                              )
                                .toFixed(2)
                                .toLocaleString()
                            }}
                            {{
                              transactionDetails.transactionDetails
                                .target_currency
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>

              <v-col lg="12" md="12" sm="12" class="box-div mt-4 mb-2">
                <div class="mt-1 p-0 outlined">
                  <div class="d-flex">
                    <v-sheet
                      class="v-sheet--offset ms-2 pt-1 text-center"
                      width="130px"
                      elevation="0"
                    >
                      {{ $t("payDetails") }}
                    </v-sheet>
                  </div>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="text-left">
                            {{ $t("payType") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.paymentDetails.payment_type}`
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("purpose_of_remittance") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.paymentDetails.reason_text}`
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("sourceofFund") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.paymentDetails.sourceoffund_text}`
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("remarks") }}
                          </td>
                          <td class="text-left">
                            {{ `${transactionDetails.paymentDetails.remarks}` }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            transactionDetails.paymentDetails.bank_advice
                              .name &&
                            transactionDetails.paymentDetails.payment_type ===
                              'Bank Transfer/Cash Deposit'
                          "
                        >
                          <td class="text-left">
                            {{ $t("bank_advice") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.paymentDetails.bank_advice.name}`
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            transactionDetails.paymentDetails.cust_invoice
                              .name &&
                            transactionDetails.paymentDetails.payment_type ===
                              'Bank Transfer/Cash Deposit'
                          "
                        >
                          <td class="text-left">
                            {{ $t("invoiceadvice") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.paymentDetails.cust_invoice.name}`
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>

              <v-col
                lg="12"
                md="12"
                sm="12"
                class="mt-2 box-div mb-2"
                v-if="
                  !(
                    transactionDetails.accountDetails &&
                    transactionDetails.accountDetails.isSelf
                  )
                "
                ><div class="mt-1 p-0 outlined">
                  <div class="d-flex">
                    <v-sheet
                      class="v-sheet--offset ms-2 pt-1 text-center"
                      width="180px"
                      elevation="0"
                    >
                      {{ $t("otherDetails") }}
                    </v-sheet>
                  </div>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="text-left">
                            {{ $t("name") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.fname} ${transactionDetails.accountDetails.benficiaryDetails.lname}`
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("email") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.email} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("phone") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.phone} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("dob") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.dob} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("occupation") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.occupation} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("sourceFund") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.source_of_fund} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("employer_name") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.employer_name} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("address") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.address} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("zipcode") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.postalCode} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("idType") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.id_type} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("idnumber") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.id_number} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("expDate") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.expiry_date} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("issueDate") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.issue_date} `
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("issueloc") }}
                          </td>
                          <td class="text-left">
                            {{
                              `${transactionDetails.accountDetails.benficiaryDetails.issue_location} `
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="transactionDetails.reciepient || reciepientDetails"
          key="beneficary"
        >
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <strong>
              {{ $t("beneficaryDetails") }}
            </strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="amountsection">
              <v-col lg="12" sm="12" class="box-div mb-2">
                <div class="mt-1 p-0 outlined">
                  <div class="d-flex">
                    <v-sheet
                      class="v-sheet--offset ms-2 pt-1 text-center"
                      width="180px"
                      elevation="0"
                    >
                      {{ $t("personalInfo") }}
                    </v-sheet>
                  </div>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="text-left">
                            {{ $t("name") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="transactionDetails.reciepient"
                          >
                            {{ `${transactionDetails.reciepient.full_name}` }}
                          </td>
                          <td class="text-left" v-else>
                            {{ `${reciepientDetails.full_name}` }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("phone") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="transactionDetails.reciepient"
                          >
                            {{ transactionDetails.reciepient.phone_number }}
                          </td>
                          <td class="text-left" v-else>
                            {{ reciepientDetails.phone_number }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("relation") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="transactionDetails.reciepient"
                          >
                            {{
                              transactionDetails.reciepient.relationship_code
                                ? transactionDetails.reciepient
                                    .relationship_code.value
                                : ""
                            }}
                          </td>
                          <td class="text-left" v-else>
                            {{
                              reciepientDetails.relationship_code
                                ? reciepientDetails.relationship_code.value
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("zipcode") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="transactionDetails.reciepient"
                          >
                            {{ transactionDetails.reciepient.postal_code }}
                          </td>
                          <td class="text-left" v-else>
                            {{ reciepientDetails.postal_code }}
                          </td>
                        </tr>

                        <tr>
                          <td class="text-left">
                            {{ $t("email") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="transactionDetails.reciepient"
                          >
                            {{ transactionDetails.reciepient.email_id }}
                          </td>
                          <td class="text-left" v-else>
                            {{ reciepientDetails.email_id }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            {{ $t("address") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="transactionDetails.reciepient"
                          >
                            {{ transactionDetails.reciepient.address }}
                          </td>
                          <td class="text-left" v-else>
                            {{ reciepientDetails.address }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>

              <v-col
                lg="12"
                md="12"
                sm="12"
                class="box-div mt-2 mb-2"
                v-if="
                  transactionDetails.reciepient &&
                  transactionDetails.reciepient.payment_code
                    .payment_mode_code != 'A' &&
                  !is_self_beneficiary
                "
              >
                <div class="mt-1 p-0 outlined">
                  <div class="d-flex box">
                    <v-sheet
                      class="v-sheet--offset ms-2 pt-1 text-center"
                      width="110px"
                      elevation="0"
                    >
                      {{ $t("bankDetails") }}
                    </v-sheet>
                  </div>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="text-left">
                            {{ $t("bankName") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="
                              transactionDetails.reciepient.location_name &&
                              transactionDetails.reciepient.payment_code
                                .payment_mode_code
                            "
                          >
                            {{
                              `${transactionDetails.reciepient.location_name}`
                            }}
                          </td>
                          <td class="text-left" v-else>
                            {{ `${reciepientDetails.location_name}` }}
                          </td>
                        </tr>

                        <tr
                          v-if="
                            transactionDetails.reciepient &&
                            transactionDetails.reciepient.payment_code
                              .payment_mode_code === 'B'
                          "
                        >
                          <td class="text-left">
                            {{ $t("bankAccount") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="transactionDetails.reciepient"
                          >
                            {{
                              `${transactionDetails.reciepient.bank_account_no}`
                            }}
                          </td>
                          <td class="text-left" v-else>
                            {{ `${reciepientDetails.bank_account_no}` }}
                          </td>
                        </tr>

                        <tr
                          v-if="
                            transactionDetails.reciepient &&
                            transactionDetails.reciepient.payment_code
                              .payment_mode_code === 'B'
                          "
                        >
                          <td class="text-left">
                            {{ $t("bankAddress") }}
                          </td>
                          <td
                            class="text-left"
                            v-if="transactionDetails.reciepient"
                          >
                            {{ `${transactionDetails.reciepient.branch_code}` }}
                          </td>
                          <td class="text-left" v-else>
                            {{ `${reciepientDetails.branch_code}` }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <h5>{{ $t("declaration") }} :</h5>
        <p>
          {{ $t("declaration_lines") }}
        </p>
        <!-- <ul class="lifont">
          <li class="p-0">
            {{ $t("declration_ln1") }}
          </li>
          <li class="p-0">
            {{ $t("declration_ln2") }}
          </li>
          <li class="p-0">
            {{ $t("declration_ln3") }}
          </li>
          <li class="p-0">
            {{ $t("declration_ln4") }}
          </li>
          <li class="p-0">
            {{ $t("declration_ln5") }}
          </li>
          <li class="p-0">
            {{ $t("declration_ln6") }}
          </li>
          <li class="p-0">
            {{ $t("declration_ln7") }}
          </li>
          <li class="p-0">{{ $t("declration_ln8") }}</li>
        </ul> -->
      </v-col>
      <v-col cols="12" md="12"
        ><div class="d-flex justify-content-left align-items-center">
          <v-checkbox v-model="isdeclared" color="orange darken-3"></v-checkbox>
          <h6 class="mt-2">{{ $t("i_make_declaration") }}</h6>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <v-btn
          class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
          outlinedBank
          Transfer
          @click="onClickBack"
          ><v-icon dark left>mdi-arrow-left</v-icon>
          {{ $t("backLabel") }}</v-btn
        >

        <!-- <v-btn
          v-if="
            !['null', undefined, '', null].includes(isUser_type) &&
            isUser_type === 'IND' &&
            transactionDetails.paymentDetails.payment_type ===
              'FPX (Internet Banking)' &&
            !is_partner
          "
          :disabled="!isdeclared"
          color="primary"
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onPay"
        >
          {{ $t("Pay") }}<i class="fa-solid fa-circle-dollar-to-slot ps-2"></i
        ></v-btn> -->
        <!-- <v-btn
          v-if="
            !['null', undefined, '', null].includes(isUser_type) &&
            isUser_type === 'IND' &&
            transactionDetails.paymentDetails.payment_type === 'E-Wallets' &&
            !is_partner
          "
          :disabled="!isdeclared"
          color="primary"
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onPay"
        >
          {{ $t("Pay") }}<i class="fa-solid fa-circle-dollar-to-slot ps-2"></i
        ></v-btn> -->
        <v-btn
          v-if="
            !['null', undefined, '', null].includes(isUser_type) &&
            is_payment_gateway
          "
          :disabled="!isdeclared"
          color="primary"
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onPay"
          >{{
            transactionDetails.paymentDetails.payment_type ===
            "Bank Transfer/Cash Deposit"
              ? $t("Proceed")
              : $t("Pay")
          }}
          <i class="fa-solid fa-circle-dollar-to-slot ps-2"></i
        ></v-btn>
        <v-btn
          v-if="this.is_partner && !is_payment_gateway"
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          color="primary"
          :disabled="!isdeclared"
          @click="is_check_pin ? (isWallet = true) : handleEvolte()"
          >{{ is_check_pin ? $t("Confirm with PIN") : $t("Pay") }}
          <i class="fa-solid fa-circle-dollar-to-slot ps-2"></i
        ></v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="isWallet" @click:outside="volte_pin = null" width="350">
      <loading :active="isWalletLoader" :opacity="0.7" color="#ff6b00" />
      <v-card class="d-flex main-card flex-column align-items-center">
        <v-card-title>
          <img src="@/assets/master_logo.png" width="150" height="50" />
        </v-card-title>

        <div>
          <label> {{ $t("enter_evolet_virtual_phone_number") }}</label>
          <v-text-field
            placeholder="Enter E-wallet pin"
            outlined
            v-model="volte_pin"
            dense
            :rules="is_check_pin && volte_pin ? walletRules : []"
            :type="isShowPin ? 'text' : 'password'"
          >
            <template v-slot:append>
              <v-icon v-if="isShowPin" @click="isShowPin = false">
                mdi-eye
              </v-icon>

              <v-icon v-else @click="isShowPin = true"> mdi-eye-off </v-icon>
            </template>
          </v-text-field>
        </div>
        <v-card-actions>
          <v-btn color="primary" :disabled="!volte_pin" @click="handleEvolte">
            {{ $t("proceed") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertMsg.status" width="350" persistent>
      <v-card
        rounded="xl"
        class="d-flex main-card flex-column align-items-center pa-3"
      >
        <div>
          <v-icon
            :color="alertMsg.type"
            large
            class="account-icons m-auto text-center"
            :style="
              alertMsg.type === 'success'
                ? 'border: 1px solid green;'
                : 'border: 1px solid red;'
            "
          >
            {{
              alertMsg.type === "success"
                ? "mdi-checkbox-marked-circle"
                : " mdi-close-circle"
            }}
          </v-icon>
        </div>
        <p class="text-h6 text-uppercase" :class="`${alertMsg.type}--text`">
          {{ alertMsg.type }}
        </p>
        <p class="button">
          {{ alertMsg.text }}
        </p>
        <v-card-actions>
          <v-btn
            v-if="alertMsg.type === 'success'"
            :color="'success'"
            @click="pushTrans()"
          >
            {{ $t("Continue") }}
          </v-btn>
          <v-btn color="error" v-else @click="alertMsg.status = false">
            {{ $t("Try Again") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import transactionService from "@/services/TransactionService/transactionService";
import { validations } from "@/utils/validation.js";
import handleImageUpload from "../../../mixins/compressImage.mixin";
const { walletRules } = validations;
// import axios from "axios";
export default {
  data: () => {
    return {
      walletRules,
      isShowPin: false,
      isWalletLoader: false,
      isdeclared: false,
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      isWallet: false,
      panelOpened: 0,
      loader: false,
      is_partner: null,
      is_payment_gateway: null,
      isUser_type: null,
      volte_pin: null,
      reciepientDetails: {},
      paymentModeselected: "",
      is_self_beneficiary: null,
      is_check_pin: null,
    };
  },
  mixins: [handleImageUpload],
  props: {
    transactionDetails: {
      type: Object,
      required: true,
    },
  },
  components: {
    Loading,
  },
  created() {
    this.is_self_beneficiary = JSON.parse(
      sessionStorage.getItem("is_self_beneficiary")
    );
    this.is_check_pin = JSON.parse(sessionStorage.getItem("is_check_pin"));
  },
  mounted() {
    this.is_partner = this.$getLocalStorage("is_partner");
    this.isUser_type = sessionStorage.getItem("user_type");
    this.is_payment_gateway = this.$getLocalStorage("is_payment_gateway");
    console.log("transactionDetails", this.transactionDetails);
    this.reciepientDetails = this.$store.state.data;
    console.log("reciepientDetails", this.reciepientDetails);
  },
  methods: {
    pushTrans() {
      this.$router.push("/transactionSummary");
    },
    async handleEvolte() {
      this.isWalletLoader = true;
      let payload = {
        sending_amount:
          this.transactionDetails.transactionDetails.amountSection
            .finalSendAmount,
        total_coll_amount:
          this.transactionDetails.transactionDetails.exchangeData.collectAmount,
        coll_crncy_cd: this.transactionDetails.transactionDetails.base_currency,
        coll_ex_rate:
          this.transactionDetails.transactionDetails.convertData.exchangeRate,
        fx_rate_id:
          this.transactionDetails.transactionDetails.convertData.rateId,
        service_charge_id:
          this.transactionDetails.transactionDetails.convertData
            .serviceChargeId,
        payout_partner:
          this.transactionDetails.transactionDetails.convertData.payoutPartner,
        total_charge:
          this.transactionDetails.transactionDetails.exchangeData.serviceCharge,
        pay_amount:
          this.transactionDetails.transactionDetails.amountSection
            .receiveAmount,
        pay_crncy_cd:
          this.transactionDetails.transactionDetails.target_currency,
        payment_mode_cd:
          this.transactionDetails.transactionDetails.amountSection.paymentMode
            .code,
        trans_comments: this.transactionDetails.paymentDetails.remarks,
        source_of_fund_cd: this.transactionDetails.paymentDetails.sourceoffunds,
        reason_of_remittance_cd: this.transactionDetails.paymentDetails.reason,
        payment_type: "EVOLET",
        receiver_id: this.transactionDetails.reciepient.receiver_id
          ? this.transactionDetails.reciepient.receiver_id
          : this.reciepientDetails.receiver_id,
        is_self:
          (this.transactionDetails.accountDetails &&
            this.transactionDetails.accountDetails.isSelf) ||
          false,
        cal_by: this.transactionDetails.transactionDetails.cal_by,
        onbehalf_first_name:
          this.transactionDetails.accountDetails?.benficiaryDetails?.fname,
        onbehalf_last_name:
          this.transactionDetails.accountDetails?.benficiaryDetails?.lname,
        onbehalf_date_of_birth:
          this.transactionDetails.accountDetails?.benficiaryDetails?.dob,
        onbehalf_gender:
          this.transactionDetails.accountDetails?.benficiaryDetails?.gender,
        onbehalf_phone:
          this.transactionDetails.accountDetails?.benficiaryDetails?.phone,
        onbehalf_country:
          this.transactionDetails.accountDetails?.benficiaryDetails?.country,
        onbehalf_city:
          this.transactionDetails.accountDetails?.benficiaryDetails?.city,
        onbehalf_address:
          this.transactionDetails.accountDetails?.benficiaryDetails?.address,
        onbehalf_postal_code:
          this.transactionDetails.accountDetails?.benficiaryDetails?.postalCode,
        onbehalf_occupation:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.occupation_code,
        onbehalf_source_of_fund:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.source_of_fund_cd,
        onbehalf_employer_name:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.employer_name,
        onbehalf_nationality:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.nationality_code,
        onbehalf_id_type:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.id_type_code,
        onbehalf_id_number:
          this.transactionDetails.accountDetails?.benficiaryDetails?.id_number,
        onbehalf_id_expiry_date:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.expiry_date,
        onbehalf_id_issue_date:
          this.transactionDetails.accountDetails?.benficiaryDetails?.issue_date,
        onbehalf_id_issue_location:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.issue_location,
        volet_pin: this.volte_pin,
      };
      console.log("reciepientDetails", this.transactionDetails.reciepient);
      console.log("payload", payload);
      // let responseData = await this.$store.dispatch(
      //   "Transactional/PayFromWallet",
      //   payload
      // );
      try {
        const responseData = await transactionService.PayFromWallet(payload);
        this.isWalletLoader = false;

        if (responseData.data.status_code === 200) {
          this.isWallet = false;
          this.alertMsg = {
            status: true,
            text: responseData.data.message,
            color: "green",
            type: "success",
          };
          this.volte_pin = null;
          // setTimeout(() => this.$router.push("/transactionSummary"), 1000);
          await this.uploadIdfirstPage(
            responseData.data.data[0] &&
              responseData.data.data[0].transaction_id
          );
        } else {
          this.isWallet = false;
          if (responseData.data.data && responseData.data.data[0]) {
            this.alertMsg = {
              status: true,
              text: responseData.data.data && responseData.data.data[0],
              color: "red",
              timeout: 2,
              type: "error",
            };
          } else {
            this.alertMsg = {
              status: true,
              text: responseData.data.message,
              color: "red",
              timeout: 2,
              type: "error",
            };
          }
        }
      } catch (err) {
        this.isWallet = false;
        console.log(err);
      }
    },
    async onPay() {
      console.log("on pay team", this.transactionDetails);
      this.loader = true;
      const payment_type =
        this.transactionDetails.paymentDetails.payment_type ===
        "FPX (Internet Banking)"
          ? "FPX"
          : this.transactionDetails.paymentDetails.payment_type ===
            "Bank Transfer/Cash Deposit"
          ? "BD"
          : this.transactionDetails.paymentDetails.payment_type === "E-Wallets"
          ? "FPX-E"
          : "";
      let payload = {
        sending_amount:
          this.transactionDetails.transactionDetails.amountSection
            .finalSendAmount,
        total_coll_amount:
          this.transactionDetails.transactionDetails.exchangeData.collectAmount,
        coll_crncy_cd: this.transactionDetails.transactionDetails.base_currency,
        coll_ex_rate:
          this.transactionDetails.transactionDetails.convertData.exchangeRate,
        fx_rate_id:
          this.transactionDetails.transactionDetails.convertData.rateId,
        service_charge_id:
          this.transactionDetails.transactionDetails.convertData
            .serviceChargeId,
        payout_partner:
          this.transactionDetails.transactionDetails.convertData.payoutPartner,
        total_charge:
          this.transactionDetails.transactionDetails.exchangeData.serviceCharge,
        pay_amount:
          this.transactionDetails.transactionDetails.amountSection
            .receiveAmount,

        pay_crncy_cd:
          this.transactionDetails.transactionDetails.target_currency,
        // payment_mode_cd:
        //   this.transactionDetails.transactionDetails.selected_service_charge &&
        //   this.transactionDetails.transactionDetails.selected_service_charge
        //     .value,
        payment_mode_cd:
          this.transactionDetails.transactionDetails.amountSection.paymentMode
            .code,

        trans_comments: this.transactionDetails.paymentDetails.remarks,
        source_of_fund_cd: this.transactionDetails.paymentDetails.sourceoffunds,
        reason_of_remittance_cd: this.transactionDetails.paymentDetails.reason,
        payment_type: payment_type,
        receiver_id: this.transactionDetails.reciepient.receiver_id
          ? this.transactionDetails.reciepient.receiver_id
          : this.reciepientDetails.receiver_id,
        is_self:
          (this.transactionDetails.accountDetails &&
            this.transactionDetails.accountDetails.isSelf) ||
          false,
        cal_by: this.transactionDetails.transactionDetails.cal_by,
        onbehalf_first_name:
          this.transactionDetails.accountDetails?.benficiaryDetails?.fname,
        onbehalf_last_name:
          this.transactionDetails.accountDetails?.benficiaryDetails?.lname,
        onbehalf_date_of_birth:
          this.transactionDetails.accountDetails?.benficiaryDetails?.dob,
        onbehalf_gender:
          this.transactionDetails.accountDetails?.benficiaryDetails?.gender,
        onbehal_phone:
          this.transactionDetails.accountDetails?.benficiaryDetails?.phone,
        onbehalf_country:
          this.transactionDetails.accountDetails?.benficiaryDetails?.country,
        onbehalf_city:
          this.transactionDetails.accountDetails?.benficiaryDetails?.city,
        onbehalf_address:
          this.transactionDetails.accountDetails?.benficiaryDetails?.address,
        onbehalf_postal_code:
          this.transactionDetails.accountDetails?.benficiaryDetails?.postalCode,
        onbehalf_occupation:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.occupation_code,
        onbehalf_source_of_fund:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.source_of_fund_cd,
        onbehalf_employer_name:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.employer_name,
        onbehalf_nationality:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.nationality_code,
        onbehalf_id_type:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.id_type_code,
        onbehalf_id_number:
          this.transactionDetails.accountDetails?.benficiaryDetails?.id_number,
        onbehalf_id_expiry_date:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.expiry_date,
        onbehalf_id_issue_date:
          this.transactionDetails.accountDetails?.benficiaryDetails?.issue_date,
        onbehalf_id_issue_location:
          this.transactionDetails.accountDetails?.benficiaryDetails
            ?.issue_location,
      };
     try{
       // let response = await this.$store.dispatch("Transactional/pay", payload);
       let response = await transactionService.Payonline(payload);
      console.log("response:", response, this.isUser_type);
      if (
        response.data.status_code === 200 &&
        response.data.data[0] &&
        response.data.data[0].url &&
        !["null", undefined, "", null].includes(this.isUser_type) &&
        this.isUser_type === "IND" &&
        this.transactionDetails.paymentDetails?.payment_type !=
          "Bank Transfer/Cash Deposit"
      ) {
        this.transactionDetails.accountDetails?.benficiaryDetails?.first_page
          ? await this.uploadIdfirstPage(
              response.data.data[0] && response.data.data[0].transaction_id
            )
          : "";
        window.open(response.data.data[0].url, "_self");
      } else if (
        response.data.status_code === 200 &&
        response.data.data[0] &&
        this.transactionDetails.paymentDetails?.payment_type ===
          "Bank Transfer/Cash Deposit"
      ) {
        // if payment type is bank transafer then add bank advice image
        this.transactionDetails.paymentDetails?.bank_advice
          ? await this.uploadDoc(
              response.data.data[0] && response.data.data[0].transaction_id,
              "BD"
            )
          : "";
        this.transactionDetails.paymentDetails?.cust_invoice
          ? await this.uploadDoc(
              response.data.data[0] && response.data.data[0].transaction_id,
              "CIV"
            )
          : "";

        this.alertMsg = {
          status: true,
          text: "",
          color: "green",
          timeout: 2,
          type: "success",
        };
        this.loader = false;
        // this.$router.push("/transactionsummary");
      } else {
        this.loader = false;
        if (response.data.data && response.data.data[0]) {
          this.alertMsg = {
            status: true,
            text: response.data.data && response.data.data[0],
            color: "red",
            timeout: 2,
            type: "error",
          };
        } else {
          this.alertMsg = {
            status: true,
            text: response.data.message,
            color: "red",
            timeout: 2,
            type: "error",
          };
        }
      }
     }catch(err){
      this.loader = false;
      console.log(err)
     }
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      this.$emit("onPay");
    },
    //method for uploading the bank reciept uploaded in transaction details tab...//
    async uploadIdfirstPage(txn_id) {
      // const token = sessionStorage.getItem("access_token");
      let compressdata = await this.handleImageUpload(
        this.transactionDetails.accountDetails.benficiaryDetails.first_page
      );
      console.log(compressdata);
      const IdfirstPage = new FormData();
      IdfirstPage.append("document", compressdata);
      try {
        const responseData = await transactionService.uploadOnbehalfFirstPage(
          txn_id,
          IdfirstPage
        );
        // const responseData = await axios.post(
        //   `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1/qqpay/upload/on-behalf/document?transaction_id=${txn_id}`,
        //   IdfirstPage,
        //   {
        //     headers: {
        //       accept: "application/json",
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );
        return responseData;
      } catch (error) {
        return error;
      }
    },
    //method for uploading the bank reciept uploaded in transaction details tab...//
    async uploadDoc(txn_id, type) {
      // const token = sessionStorage.getItem("access_token");
      // let compressdata = await this.handleImageUpload(
      //   this.transactionDetails.paymentDetails.bank_advice
      // );
      // console.log(compressdata);
      const bankAdvice = new FormData();
      if (type == "BD") {
        bankAdvice.append(
          "remarks_document",
          this.transactionDetails.paymentDetails.bank_advice
        );
      } else {
        bankAdvice.append(
          "remarks_document",
          this.transactionDetails.paymentDetails.cust_invoice
        );
      }

      try {
        const responseData = await transactionService.uploadDocument(
          txn_id,
          type,
          bankAdvice
        );
        // const responseData = await axios.post(
        //   `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1/online/upload-bank-receipt?transaction_id=${txn_id}&type=${type}`,
        //   bankAdvice,
        //   {
        //     headers: {
        //       accept: "application/json",
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );
        return responseData;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>

<style>
.empty-custome-v-icon {
  color: gray !important;
}
.panel-sub-header {
  font-size: 18px;
  font-weight: bolder;
}
.v-sheet--offset {
  top: -14px;
  width: 250px;
  height: 30px;
  position: relative;
  border-radius: 5px;
  background-color: rgb(246, 246, 246) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.v-sheet--offset1 {
  top: -20px;
  position: relative;
  border-radius: 10px;
  background-color: rgb(131, 24, 24) !important;
}
.outlined {
  border-radius: 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.main-row {
  padding: 1rem !important;
}
.sub-div {
  padding: 1rem !important;
}
.lifont li {
  font-size: 15px;
}
</style>
