var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('loading',{attrs:{"active":_vm.loader,"is-full-page":true,"opacity":0.7,"color":"#ff6b00"}}),_c('v-row',[(_vm.isTrans == 'true')?_c('v-col',{staticClass:"d-flex w-100 justify-content-end",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onClickCancel}},[_vm._v(" "+_vm._s(_vm.$t("cancel_beneficary")))])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("businessname_companyname_benificary"))),_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")])]),_c('v-text-field',{attrs:{"dense":"","placeholder":_vm.$t('companyname_benificary'),"required":"","error-messages":_vm.companyNameErrors,"outlined":""},on:{"input":function($event){return _vm.$v.beneficiaryReqBody.full_name.$touch()},"blur":function($event){return _vm.$v.beneficiaryReqBody.full_name.$touch()}},model:{value:(_vm.beneficiaryReqBody.full_name),callback:function ($$v) {_vm.$set(_vm.beneficiaryReqBody, "full_name", $$v)},expression:"beneficiaryReqBody.full_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("company_registration_number_beneficary")))]),_c('v-text-field',{attrs:{"dense":"","placeholder":_vm.$t('company_registration_number_beneficary'),"required":"","error-messages":_vm.companyRegNumberErrors,"outlined":""},on:{"input":function($event){return _vm.$v.beneficiaryReqBody.id_no.$touch()},"blur":function($event){return _vm.$v.beneficiaryReqBody.id_no.$touch()}},model:{value:(_vm.beneficiaryReqBody.id_no),callback:function ($$v) {_vm.$set(_vm.beneficiaryReqBody, "id_no", $$v)},expression:"beneficiaryReqBody.id_no"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("nature_of_business_beneficary")))]),_c('v-autocomplete',{attrs:{"items":[
          'Government sector',
          'Military sector',
          'Private sector',
          'Technology sector',
          'Service sector',
        ],"placeholder":_vm.$t('nature_of_business_beneficary'),"dense":"","outlined":""},model:{value:(_vm.beneficiaryReqBody.nature_of_business),callback:function ($$v) {_vm.$set(_vm.beneficiaryReqBody, "nature_of_business", $$v)},expression:"beneficiaryReqBody.nature_of_business"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("address"))),_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")])]),_c('v-text-field',{attrs:{"placeholder":_vm.$t('enter_address_beneficary'),"error-messages":_vm.addressErrors,"outlined":"","dense":""},on:{"input":function($event){return _vm.$v.beneficiaryReqBody.address.$touch()}},model:{value:(_vm.beneficiaryReqBody.address),callback:function ($$v) {_vm.$set(_vm.beneficiaryReqBody, "address", $$v)},expression:"beneficiaryReqBody.address"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{},[_vm._v(" "+_vm._s(_vm.$t("relationship"))),_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")])]),_c('v-autocomplete',{attrs:{"items":_vm.relation_options,"item-text":"txt","item-value":"val","error-messages":_vm.RelationErrors,"placeholder":_vm.$t('relationship_placeholder'),"outlined":"","dense":""},on:{"input":function($event){return _vm.$v.SelectedRelationship.$touch()}},model:{value:(_vm.SelectedRelationship),callback:function ($$v) {_vm.SelectedRelationship=$$v},expression:"SelectedRelationship"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.$t("country"))),_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")])]),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('select_country_beneficary'),"items":_vm.country_options,"item-text":"txt","item-value":"val","error-messages":_vm.countryErrors,"outlined":"","required":"","dense":""},on:{"input":function($event){_vm.$v.countrySelected.$touch();
          _vm.onChangeCountry($event);}},model:{value:(_vm.countrySelected),callback:function ($$v) {_vm.countrySelected=$$v},expression:"countrySelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Province")))]),_c('v-autocomplete',{attrs:{"items":_vm.state_options,"item-text":"txt","item-value":"val","placeholder":_vm.$t('province_placeholder'),"outlined":"","required":"","dense":""},on:{"change":function($event){return _vm.onChangeState($event)}},model:{value:(_vm.stateSelected),callback:function ($$v) {_vm.stateSelected=$$v},expression:"stateSelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.$t("city")))]),_c('v-autocomplete',{attrs:{"items":_vm.city_options,"item-text":"txt","item-value":"val","placeholder":_vm.$t('city_placeholder_beneficary'),"outlined":"","required":"","dense":""},model:{value:(_vm.citySelected),callback:function ($$v) {_vm.citySelected=$$v},expression:"citySelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("postalCode")))]),_c('v-text-field',{attrs:{"error-messages":_vm.postalCodeErrors,"placeholder":_vm.$t('postalcode_placeholder_beneficary'),"outlined":"","dense":""},on:{"input":function($event){return _vm.$v.beneficiaryReqBody.postal_code.$touch()}},model:{value:(_vm.beneficiaryReqBody.postal_code),callback:function ($$v) {_vm.$set(_vm.beneficiaryReqBody, "postal_code", $$v)},expression:"beneficiaryReqBody.postal_code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"float-right mt-5 px-5 btn-primary-outlined text-capitalize",attrs:{"outlined":""},on:{"click":_vm.onClickNext}},[_vm._v(_vm._s(_vm.$t("next"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }