var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-body"},[_c('TitleBar',{attrs:{"title":_vm.$t('fundTransfer')}}),_c('v-container',[(_vm.alertMsg.status)?_c('v-alert',{attrs:{"timeout":_vm.alertMsg.timeout,"color":_vm.alertMsg.color,"type":_vm.alertMsg.type,"dismissible":""}},[_vm._v(" "+_vm._s(_vm.alertMsg.text)+" ")]):_vm._e(),_c('v-row',{staticClass:"mt-0 d-flex justify-content-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"col":"","sm":"12","md":"12","lg":"12"}},[_c('v-stepper',{staticClass:"transaction_main",model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 1,"step":"1"}},[_vm._v(_vm._s(_vm.$t("amount")))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 2,"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t("you"))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 3,"step":"3"}},[_vm._v(" "+_vm._s(_vm.$t("recipient"))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 4,"step":"4"}},[_vm._v(" "+_vm._s(_vm.$t("transactionDetails")))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 5,"step":"5"}},[_vm._v(_vm._s(_vm.$t("review&pay")))])],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pt-0",attrs:{"step":"1"}},[_c('AmountSection',{attrs:{"transactionDetails":this.transaction_details.transactionDetails},on:{"onNextClick":_vm.onClickNext,"isBeneSummary":_vm.isBeneSummary}})],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('account-type',{attrs:{"accountDetails":this.transaction_details.accountDetails},on:{"onAccountNext":_vm.onClickNext,"onClickBack":_vm.onClickBack}})],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('Recipient',{attrs:{"beneficiaryData":this.transaction_details.reciepient,"serviceCharge":{
                  selected_service_charge:
                    this.transaction_details.transactionDetails &&
                    this.transaction_details.transactionDetails
                      .selected_service_charge,
                  selected_country:
                    this.transaction_details.transactionDetails &&
                    this.transaction_details.transactionDetails
                      .selected_country,
                  country_name:
                    this.transaction_details.transactionDetails &&
                    this.transaction_details.transactionDetails.country_name,
                }},on:{"onNextClick":_vm.onClickNext,"onClickBack":_vm.onClickBack}})],1),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('account-section',{attrs:{"self":this.transaction_details.acountDetails &&
                  this.transaction_details.acountDetails.self,"paymentDetails":this.transaction_details.paymentDetails},on:{"onClickNext":_vm.onClickNext,"onClickBack":_vm.onClickBack}})],1),_c('v-stepper-content',{attrs:{"step":"5"}},[(_vm.currentStep === 5)?_c('Summary',{attrs:{"transactionDetails":_vm.transaction_details},on:{"onClickBack":_vm.onClickBack}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }