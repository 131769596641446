<template>
  <v-container>
    <v-form ref="basicDetails" name="basicDetails">
      <v-row>
        <v-col cols="12" md="6">
          <label>{{ $t("firstName") }}<span style="color: #f00">*</span></label>
          <v-text-field
            v-model="beneficiary.fname"
            class=""
            :rules="firstnameValidations"
            :placeholder="$t('firstname_beneficary')"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("lastname_beneficary")
            }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            v-model="beneficiary.lname"
            :placeholder="$t('lastname_beneficary')"
            class=""
            required
            dense
            :rules="lastnameValidations"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <label label-for="input-gender"
            >{{ $t("nationality_business") }}
            <span style="color: #f00">*</span></label
          >
          <v-autocomplete
            :placeholder="$t('nationality_placeholder')"
            dense
            :rules="nationalityValidations"
            v-model="beneficiary.nationality"
            :items="nationality_options"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label class=""
            >{{ $t("idType") }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            v-model="beneficiary.id_type"
            :placeholder="$t('id_type_placeholder')"
            :items="idtypes"
            outlined
            :rules="idtypeValidations"
            required
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("idNumber") }}
            <span style="color: #f00">*</span>
          </label>
          <v-text-field
            :placeholder="$t('id_number_placeholder_beneficary')"
            dense
            v-model="beneficiary.id_number"
            :rules="idnumberValidations"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="beneficiary.id_type != 'MYKAD' && beneficiary.id_type != 'MyPR'"
        >
          <label>{{ $t("isuueDate") }}<span style="color: #f00">*</span></label>
          <v-text-field
            v-model="beneficiary.issue_date"
            :placeholder="$t('isuueDate')"
            dense
            required
            :rules="issuedateValidations"
            outlined
            type="date"
            :max="currentDate"
            :error-messages="
              issueError ? 'Issue Date must less than today' : ''
            "
            :error="issueError"
            @change="ValidateIssue"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-if="beneficiary.id_type != 'MYKAD' && beneficiary.id_type != 'MyPR'"
        >
          <label
            >{{ $t("expiryDate") }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            v-model="beneficiary.expiry_date"
            :placeholder="$t('expiryDate')"
            dense
            outlined
            required
            :rules="expirydateValidations"
            :min="currentDate"
            :error-messages="
              expiryError ? 'Expiry Date must grater than today' : ''
            "
            :error="expiryError"
            @change="ValidateExpiry"
            type="date"
          />
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("date_of_birth_benificary")
            }}<span style="color: #f00">*</span></label
          >

          <v-menu
            ref="dobmenu"
            v-model="dobmenu"
            :close-on-content-click="false"
            lazy
            transition="scale-transition"
            :offset-x="true"
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="beneficiary.dob"
                :rules="dobValidations"
                persistent-hint
                clearable
                v-on="on"
                type="date"
                dense
                @change="ValidateAge"
                :error-messages="isDobError ? $t('dob_errormessage') : ''"
                :error="isDobError"
                :min="minDate"
                :max="maxDate"
                outlined
              >
              </v-text-field>
            </template>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("address_ind") }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            class=""
            :placeholder="$t('address_ind')"
            required
            v-model="beneficiary.address"
            :rules="addressValidations"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label :description="$t('optional')">
            {{ $t("phoneNumber") }}<span style="color: #f00">*</span>
          </label>
          <vue-phone-number-input
            default-country-code="MY"
            v-model="beneficiary.phone"
            :rules="phonenumberValidations"
            @update="onChangeNumber"
            :error="!isValidNumber"
            class="mb-3"
            required
          />
          <!-- <div style="height: 10px"></div> -->
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("occupation_beneficary")
            }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            :items="occupation_options"
            v-model="beneficiary.occupation"
            :placeholder="$t('occupation_placeholder')"
            outlined
            :rules="OcupationValidations"
            required
            dense
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <label class="required"
            >{{ $t("employer_name") }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            :placeholder="$t('employer_name')"
            required
            v-model="beneficiary.employer_name"
            :rules="empoyerNameValidations"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("sourceFund") }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            :items="sourceOfFundsList"
            v-model="beneficiary.source_of_fund"
            :placeholder="$t('sourceFund')"
            outlined
            :rules="sourceoffundsValidation"
            required
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label label-for="input-gender">{{ $t("Gender") }}</label>
          <v-autocomplete
            id="input-gender"
            :placeholder="$t('gender_placeholder')"
            :items="genderOptions"
            v-model="beneficiary.gender"
            class=""
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row> </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <label
            >{{ $t("postalCode") }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            v-model="beneficiary.postalCode"
            :placeholder="$t('postalcode_placeholder')"
            type="number"
            hide-spin-buttons
            outlined
            dense
            :rules="postalcodeValidations"
            class=""
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label :description="$t('optional')">
            {{ $t("emailAddress") }}
          </label>
          <v-text-field
            :placeholder="$t('emailid')"
            class="mb-3"
            type="email"
            dense
            v-model="beneficiary.email"
            :rules="beneficiary.email ? emailValidations : []"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <label
            >{{ $t("IDFirstPage") }}<span style="color: #f00">*</span></label
          >
          <v-file-input
            v-model="beneficiary.first_page"
            :rules="firstPageValidations"
            dense
            accept="image/png, image/jpeg, image/bmp"
            :placeholder="$t('idtype_first_page_placeholder')"
            outlined
            ref="idfirstfile"
            required
            prepend-icon=""
            @click:clear="handleFileClear"
            @change="fileUpload($event, 'FRONT_PAGE')"
          >
            <template v-slot:append>
              <v-icon
                aria-hidden="false"
                class="color-primary"
                @click="openIdfirst"
              >
                mdi-cloud-upload
              </v-icon>
            </template>

            <template v-slot:append-outer>
              <v-img
                v-if="frontPageUrl"
                class="ml-1"
                max-height="40"
                max-width="50"
                :src="frontPageUrl"
                @click="handleImageViewer(frontPageUrl)"
              />
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("location")
            }}<span
              v-if="beneficiary.id_type === 'Passport'"
              style="color: #f00"
              >*</span
            ></label
          >
          <v-autocomplete
            v-model="beneficiary.issue_location"
            :rules="
              beneficiary.id_type === 'Passport' ? issuelocationValidations : ''
            "
            :items="country_options"
            :placeholder="$t('location')"
            :disabled="disableIssueLoc"
            dense
            outlined
            required
          />
        </v-col>
      </v-row>
    </v-form>
    <image-viewer
      :showDialog="isImageViewer"
      :picture="{
        PicHeight: '350',
        PicWidth: 600,
        Pic: imageViewerUrl,
      }"
      @closeImageViewer="handleCloseImageViewer"
    ></image-viewer>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { required, numeric, alphaNum } from "vuelidate/lib/validators";
import { validations } from "@/utils/validation";
import { findDatePrevious } from "../../../utils/helper";
import imageViewer from "../../tools/imageViewer.vue";
import commonService from "@/services/CommonService/commonService";

let {
  issuedateValidations,
  issuelocationValidations,
  expirydateValidations,
  idnumberValidations,
  idtypeValidations,
  sourceoffundsValidation,
  postalcodeValidations,
  addressValidations,
  phonenumberValidations,
  emailValidations,
  OcupationValidations,
  nationalityValidations,
  dobValidations,
  firstnameValidations,
  lastnameValidations,
  firstPageValidations,
  empoyerNameValidations,
} = validations;
export default {
  name: "BasicDetails",
  data: () => ({
    currentDate: new Date().toJSON().split("T")[0],
    expiryError: false,
    issueError: false,
    maxDate: null,
    minDate: null,
    nationality_options: [],
    occupation_options: [],
    sourceOfFundsList: [],
    sourceOfFundsobj: [],
    nationality_obj: [],
    occupation_obj: [],
    issuedateValidations,
    issuelocationValidations,
    expirydateValidations,
    idnumberValidations,
    sourceoffundsValidation,
    idtypeValidations,
    postalcodeValidations,
    empoyerNameValidations,
    addressValidations,
    phonenumberValidations,
    emailValidations,
    OcupationValidations,
    nationalityValidations,
    dobValidations,
    firstPageValidations,
    firstnameValidations,
    lastnameValidations,
    isValidNumber: false,
    relation_options: [],
    genderOptions: [
      { text: "Male", value: "Male" },
      { text: "Female", value: "Female" },
    ],
    isSubmitted: false,
    beneficiary: {
      fname: "",
      lname: "",
      phone: "",
      email: "",
      nationality: "",
      nationality_code: "",
      postalCode: "",
      occupation: "",
      occupation_code: "",
      source_of_fund: "",
      source_of_fund_cd: "",
      employer_name: "",
      dob: null,
      gender: "",
      id_type: "",
      issue_date: null,
      issue_location: "",
      id_number: "",
      address: "",
      expiry_date: null,
      first_page: null,
    },
    country_options: [],
    isDobError: false,
    dobmenu: false,
    idtypes: [],
    idtype_objs: [],
    disableIssueLoc: false,
    frontPageUrl: null,
    isImageViewer: false,
    imageViewerUrl: "",
  }),
  props: {
    beneficiaryDetails: {
      type: Object,
    },
  },
  components: {
    VuePhoneNumberInput,
    imageViewer,
  },
  computed: {
    ...mapGetters("Transactional", ["realtion_list"]),
    ...mapGetters("Authentication", ["country_list"]),
    fnameErrors() {
      const errors = [];
      if (!this.$v.beneficiary.fname.$dirty) return errors;
      !this.$v.beneficiary.fname.required &&
        errors.push("Please enter first name.");
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.beneficiary.lname.$dirty) return errors;
      !this.$v.beneficiary.lname.required &&
        errors.push("Please enter last name.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.beneficiary.phone.$dirty) return errors;
      !this.$v.beneficiary.phone.required &&
        errors.push("Please enter contact number.");
      return errors;
    },
    nationalityErrors() {
      const errors = [];
      if (!this.$v.beneficiary.nationality.$dirty) return errors;
      !this.$v.beneficiary.nationality.required &&
        errors.push("Please select Nationality.");
      return errors;
    },
    firstPageErrors() {
      const errors = [];
      if (!this.$v.beneficiary.first_page.$dirty) return errors;
      !this.$v.beneficiary.first_page.required &&
        errors.push(this.$t("file_select_placeholder"));
      return errors;
    },
    dobErrors() {
      const errors = [];
      if (!this.$v.beneficiary.dob.$dirty) return errors;
      !this.$v.beneficiary.dob.required &&
        errors.push("Date of Birth is Required.");
      return errors;
    },
    idNumberErrors() {
      const errors = [];
      if (!this.$v.beneficiary.id_number.$dirty) return errors;
      !this.$v.beneficiary.id_number.required &&
        errors.push(this.$t("enter_id_number"));

      switch (this.beneficiary.id_type) {
        case "Passport":
        case "Driving Licence":
          if (!this.$v.beneficiary.id_number.alphaNum) {
            errors.push(this.$t("invalid_id_num"));
          }
          break;
        case "IC":
          if (!this.$v.beneficiary.id_number.numeric)
            errors.push(this.$t("invalid_id_num"));
          if (
            this.beneficiary.id_number.length < 12 ||
            this.beneficiary.id_number.length > 12
          )
            errors.push(this.$t("ID number must be 12 digits only"));
          break;
        case "Company Registration":
        case "MyPR":
        case "Other":
        case "Work Permit":
          if (!this.$v.beneficiary.id_number.numeric)
            errors.push(this.$t("invalid_id_num"));
          break;
        default:
          break;
      }
      return errors;
    },
  },
  validations() {
    return {
      beneficiary: {
        fname: {
          required,
        },
        lname: {
          required,
        },
        phone: {
          required,
        },
        nationality: {
          required,
        },
        dob: {
          required,
        },

        id_number: {
          required,
          numeric,
          alphaNum,
        },
        first_page: {
          required,
        },
      },
    };
  },
  mounted() {
    // this.getRelationship();
    this.getidtypes();
    this.getOccupation();
    this.getSourceOfFunds();
    this.getNationalityList();
    this.getCountryList();
    this.maxDate = new Date().toJSON().slice(0, 10);
    console.log("Ddd", this.maxDate);
    this.maxDate = findDatePrevious(new Date(), 18);
    this.minDate = findDatePrevious(new Date(), 70);
  },
  watch: {
    "beneficiary.id_type"(val) {
      this.idtype_objs.map((i) => {
        if (val === i.value) {
          this.beneficiary.id_type_code = i.value_code;
        }
      });
      if (val == "MYKAD" || val == "MyPR") {
        this.beneficiary.issue_location = "Malaysia";
        this.disableIssueLoc = true;
      } else {
        this.beneficiary.issue_location = "";
        this.disableIssueLoc = false;
      }
    },
    "beneficiary.nationality"(newVal) {
      if (newVal) {
        this.nationality_obj.map((ele) => {
          if (ele.nationality === newVal) {
            this.beneficiary.nationality_code = ele.iso_alpha3;
          }
        });
      }
    },
    "beneficiary.source_of_fund"(newVal) {
      console.log(newVal);
      if (newVal) {
        this.sourceOfFundsobj.map((ele) => {
          if (ele.value === newVal) {
            this.beneficiary.source_of_fund_cd = ele.value_code;
          }
        });
      }
    },
    "beneficiary.occupation"(newVal) {
      console.log(newVal);
      if (newVal) {
        this.occupation_obj.map((ele) => {
          if (ele.value === newVal) {
            this.beneficiary.occupation_code = ele.value_code;
          }
        });
      }
    },
    beneficiaryDetails: {
      immediate: true,
      handler(val) {
        if (val.id_type) {
          this.beneficiary = val;
        }
      },
    },
  },
  methods: {
    handleFileClear() {
      this.frontPageUrl = "";
    },
    ValidateExpiry(value, e) {
      console.log(value, e);
      if (!value) {
        this.expiryError = false;
      } else {
        this.expiryError = !(value > this.currentDate);
      }
    },
    ValidateIssue(value, e) {
      console.log(value, e);
      if (!value) {
        this.issueError = false;
      } else {
        this.issueError = !(value < this.currentDate);
      }
    },
    handleCloseImageViewer() {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = "";
    },
    handleImageViewer(url) {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = url;
    },
    async fileUpload(fileObject, doc_type) {
      const imageurl = await URL.createObjectURL(fileObject);
      if (doc_type == "FRONT_PAGE") this.frontPageUrl = imageurl;
    },
    openIdfirst() {
      this.$refs.idfirstfile.$el.querySelector("input").click();
    },
    async getidtypes() {
      // let response = await this.$store.dispatch("Transactional/getIDtypes");
      // response.data.data.map((idtype) => {
      //   this.idtypes.push(idtype.value);
      //   this.idtype_objs.push(idtype);
      // });
      const responseData = await commonService.getDropdownList("DOC");
      console.log("ID Types:", responseData);
      try {
        if (responseData.data.status_code == 200) {
          responseData.data.data.map((idtype) => {
            this.idtypes.push(idtype.value);
            this.idtype_objs.push(idtype);
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getNationalityList() {
      const responseData = await commonService.getNationalityList();
      console.log("Nationality:", responseData);
      try {
        if (responseData.status == 200) {
          this.nationality_options = await responseData.data.data.map(
            (i) => i.nationality
          );
          this.nationality_obj = responseData.data.data;
        }
      } catch (err) {
        console.log(err);
      }
      // let responseData = await this.$store.dispatch("Transactional/getNationalityList");

      // this.nationality_options = await responseData.data.data.map((i) => i.nationality);
      // this.nationality_obj = responseData.data.data;
    },
    async getOccupation() {
      const responseData = await commonService.getDropdownList("OCC");
      console.log("Occupation:", responseData);
      try {
        if (responseData.data.status_code == 200) {
          this.occupation_options = await responseData.data.data.map(
            (i) => i.value
          );
          this.occupation_obj = responseData.data.data;
        }
      } catch (err) {
        console.log(err);
      }
      // const responseData = await this.$store.dispatch("Transactional/getOccupation");

      // if (Array.isArray(responseData.data.data) && responseData.data.data.length > 0) {
      // this.occupation_options = await responseData.data.data.map((i) => i.value);
      // this.occupation_obj = responseData.data.data;
      // }
    },
    async getSourceOfFunds() {
      try {
        const responseData = await commonService.getDropdownList("SOF");
        if (responseData.data.status_code === 200) {
          this.sourceOfFundsList =
            responseData.data && responseData.data.data.map((e) => e.value);
          this.sourceOfFundsobj =
            responseData.data && responseData.data.data.map((e) => e);
        }

        console.log("Funds", responseData);
      } catch (e) {
        console.error(e);
      }
    },
    onChangeNumber(e) {
      console.log("validity", e.isValid);
      this.isValidNumber = e.isValid;
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let tempNumb = this.beneficiary.phone.replace(/ /g, "");
      this.beneficiary.phone = tempNumb;
      this.$emit("onNextClick", { basicDetails: this.beneficiary });
    },
    async getRelationship() {
      // await this.$store.dispatch("Transactional/getRelationship");
      // if (this.realtion_list.response.status_code == 200) {
      // const extractedData = this.realtion_list.response.data;
      // extractedData.forEach((option) => {
      //   this.relation_options.push(option.value);
      // });
      // }
      const responseData = await commonService.getDropdownList("REL");
      console.log("Relations:", responseData);
      try {
        if (responseData.data.status_code == 200) {
          const extractedData = this.realtion_list.response.data;
          extractedData.forEach((option) => {
            this.relation_options.push(option.value);
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getCountryList() {
      // const responseData = await commonService.getDropdownList("DOC");
      // console.log("ID Types:", responseData);
      // try {
      //   if (responseData.data.status_code == 200) {
      //     responseData.data.data.map((idtype) => {
      //       this.idtypes.push(idtype.value);
      //       this.idtype_objs.push(idtype);
      //     });
      //   }
      // } catch (err) {
      //   console.log(err);
      // }
      await this.$store.dispatch("Authentication/getCountryService");
      console.log("this.country_list", this.country_list);
      this.country_options = await this.country_list.country.map(
        (i) => i.country_name
      );
    },

    ValidateAge(value, e) {
      console.log(value, e);

      if (!value) {
        this.isDobError = false;
      } else {
        this.isDobError = !(value <= this.maxDate && value >= this.minDate);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-name {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
</style>
